import * as React from 'react';
import {
  FlatList,
  LayoutChangeEvent,
  NativeScrollEvent,
  NativeSyntheticEvent,
} from 'react-native';
import {RewardLevel} from '@youtoken/ui.resource-rewards-v3';
import {Box} from '@youtoken/ui.primitives';
import {LEVELS_ITEM_WIDTH} from './constants';
import {RewardsLevelsItem, RewardsLevelsItemProps} from './Item';

export {RewardsLevelsVariant} from './types';

interface RewardsLevelsProps
  extends Pick<RewardsLevelsItemProps, 'variant' | 'onChangeCurrentLevel'> {
  currentLevel: number;
  levels: RewardLevel[];
}

export const RewardsLevels: React.FC<RewardsLevelsProps> = ({
  levels,
  currentLevel,
  onChangeCurrentLevel,
  variant,
}) => {
  const ref = React.useRef<FlatList>();

  const [layoutSize, setLayoutSize] = React.useState(null);

  const layoutSizeWidth = layoutSize?.width ?? 0;

  const layoutSizeWidthCalculated = layoutSizeWidth > 0;

  const scrollPadding = (layoutSizeWidth - LEVELS_ITEM_WIDTH) / 2;

  const goToLevel = React.useCallback(
    (level: number, animated: boolean = true) => {
      ref.current?.scrollToIndex({
        index: levels.findIndex(l => l.level === level),
        animated,
      });
    },
    [levels]
  );

  const onLayout = React.useCallback(
    ({nativeEvent: {layout}}: LayoutChangeEvent) => {
      setLayoutSize(layout);
    },
    []
  );

  const getItemLayout = React.useCallback(
    (data, index) => {
      return {
        index,
        length: LEVELS_ITEM_WIDTH,
        offset: LEVELS_ITEM_WIDTH * index,
      };
    },
    [LEVELS_ITEM_WIDTH]
  );

  const onScroll = React.useCallback(
    ({
      nativeEvent: {
        contentOffset: {x},
        contentSize: {width},
      },
    }: NativeSyntheticEvent<NativeScrollEvent>) => {
      const widthWithoutPaddings = width - scrollPadding * 2;

      const floatIndex = Math.round((x / widthWithoutPaddings) * levels.length);

      const index = Math.min(Math.max(floatIndex, 0), levels.length - 1);

      if (levels[index]) {
        onChangeCurrentLevel(levels[index].level);
      }
    },
    [scrollPadding, levels.length, onChangeCurrentLevel]
  );

  const renderItem = React.useCallback(
    ({item}) => {
      return (
        <RewardsLevelsItem
          variant={variant}
          data={item}
          active={item.level === currentLevel}
          onChangeCurrentLevel={goToLevel}
        />
      );
    },
    [variant, currentLevel, goToLevel]
  );

  const keyExtractor = React.useCallback(item => {
    return item.level;
  }, []);

  React.useEffect(() => {
    if (layoutSizeWidthCalculated) {
      goToLevel(currentLevel, false);
    }
  }, [ref.current, layoutSizeWidthCalculated]);

  return (
    <Box onLayout={onLayout}>
      <FlatList
        ref={ref}
        scrollEventThrottle={100}
        onScroll={onScroll}
        data={levels}
        renderItem={renderItem}
        keyExtractor={keyExtractor}
        getItemLayout={getItemLayout}
        horizontal
        pagingEnabled
        snapToInterval={LEVELS_ITEM_WIDTH}
        showsHorizontalScrollIndicator={false}
        style={{
          //@ts-ignore
          scrollPadding, // only for web
        }}
        contentContainerStyle={{
          paddingHorizontal: scrollPadding,
        }}
      />
    </Box>
  );
};
