import {createFeature, getResourceDescriptor} from '@youtoken/ui.data-storage';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {WalletsResource} from '@youtoken/ui.resource-wallets';
import {LoyaltyResource} from '@youtoken/ui.resource-loyalty';
import {SavingsOverviewResource} from '@youtoken/ui.resource-savings';
import {computedFn} from 'mobx-utils';

export class AccountLevelAllBenefitsFeature extends createFeature({
  getKey: () => `AccountLevelAllBenefits`,
  getResources: (): {
    wallets: readonly [typeof WalletsResource, object];
    loyalty: readonly [typeof LoyaltyResource, object];
    savingsOverview?: readonly [typeof SavingsOverviewResource, object];
  } => {
    const authMe = AuthMeResource.__DANGEROUSLY__getInstanceStatically({});

    const commonResources = {
      wallets: getResourceDescriptor(WalletsResource, {}),
      loyalty: getResourceDescriptor(LoyaltyResource, {}),
    };

    if (authMe.savings4Active) {
      return {
        ...commonResources,
        savingsOverview: getResourceDescriptor(SavingsOverviewResource, {}),
      };
    }

    return commonResources;
  },
}) {
  getSavingsData = computedFn((level: number) => {
    const {
      status,
      cardColor,
      saving: {maxTickersAPR, limit, period, assets, tickerAmount},
    } = this.resources.loyalty.getLevel(level);

    const tickersKeys = this.resources.savingsOverview?.data.tickersKeys ?? [];

    const selected = assets.filter(({ticker}) => {
      return tickersKeys.includes(ticker);
    });

    const other = assets.filter(({ticker}) => {
      return !tickersKeys.includes(ticker);
    });

    return {
      status,
      cardColor,
      maxTickersAPR,
      limit,
      tickerAmount,
      period,
      selected,
      other,
    };
  });
}
