import * as React from 'react';
import {useIsMobile} from '@youtoken/ui.primitives';
import {useIllustrationSource} from '@youtoken/ui.elements';
import {Platform} from 'react-native';
import {VideoPreview} from './VideoPreview';

export const LoansVideoPreview = React.memo(() => {
  const isMobile = useIsMobile();
  const {source} = useIllustrationSource('illustration-8');

  return (
    <VideoPreview
      testID="LOAN_LIST_VIDEO_PREVIEW"
      cover={source}
      videoUrl={Platform.select({
        web: isMobile
          ? 'https://player.vimeo.com/video/724771783'
          : 'https://player.vimeo.com/video/724772071',
        native:
          'https://player.vimeo.com/progressive_redirect/playback/724771783/rendition/1080p/file.mp4?loc=external&signature=69b48229694fff3034f3b09c10d09f595e123af65de7c35937e146684f5b73df',
      })}
    />
  );
});

export const SavingsVideoPreview = React.memo(() => {
  const {source} = useIllustrationSource('illustration-7');

  return (
    <VideoPreview
      cover={source}
      videoUrl={Platform.select({
        web: 'https://www.youtube.com/embed/lICkr2qI0zI',
        native:
          'https://player.vimeo.com/external/592071259.hd.mp4?s=dd8ddc32967e066c42168a3a2159bef6e045d7d6&profile_id=175',
      })}
    />
  );
});

export const MultiHODLVideoPreview = React.memo(() => {
  const isMobile = useIsMobile();

  const {source} = useIllustrationSource('illustration-3');

  return (
    <VideoPreview
      cover={source}
      videoUrl={Platform.select({
        web: isMobile
          ? 'https://www.youtube.com/embed/gNTBx4j2zug'
          : 'https://www.youtube.com/embed/Mg5P2gFS0gE',
        native:
          'https://player.vimeo.com/external/592072716.hd.mp4?s=2d317314280c40c2489255d7fbc73a7de9371f40&profile_id=175',
      })}
    />
  );
});

export const TurbochargePreview = React.memo(() => {
  const isMobile = useIsMobile();

  const {source} = useIllustrationSource('illustration-6');

  return (
    <VideoPreview
      cover={source}
      videoUrl={Platform.select({
        web: isMobile
          ? 'https://player.vimeo.com/video/715929661'
          : 'https://player.vimeo.com/video/715931347',
        native:
          'https://player.vimeo.com/progressive_redirect/playback/715929661/rendition/1080p/file.mp4?loc=external&signature=cfcbeed045a0665b2c132745c875fdbf17d145df26b884e9c2337e6005095dfd',
      })}
    />
  );
});

export const DualPreview = React.memo(() => {
  const isMobile = useIsMobile();

  const {source} = useIllustrationSource('illustration-5');

  return (
    <VideoPreview
      cover={source}
      videoUrl={Platform.select({
        web: isMobile
          ? 'https://player.vimeo.com/video/726758673'
          : 'https://player.vimeo.com/video/726757338',
        native:
          'https://player.vimeo.com/progressive_redirect/playback/726758673/rendition/1080p/file.mp4?loc=external&signature=ad5c000384f18d26587faf7cb1841ea208eefb4f05a0644ef441f377f41a2ff9',
      })}
    />
  );
});

export const MinerPreview = React.memo(() => {
  const isMobile = useIsMobile();

  const {source} = useIllustrationSource('illustration-7');

  return (
    <VideoPreview
      cover={source}
      videoUrl={Platform.select({
        web: isMobile
          ? 'https://player.vimeo.com/video/788319591?h=e5dce7fb64'
          : 'https://player.vimeo.com/video/788371426?h=a88c01eef8',

        native:
          'https://player.vimeo.com/progressive_redirect/playback/788319591/rendition/1080p/file.mp4?loc=external&signature=7b5c721eb31bb6e9fe8571162e055baf92e5637652836a25e467888ff88acc55',
      })}
    />
  );
});

export const SavingsV4VideoPreview = React.memo(() => {
  const isMobile = useIsMobile();

  const {source} = useIllustrationSource('illustration-7');

  return (
    <VideoPreview
      cover={source}
      videoUrl={Platform.select({
        web: isMobile
          ? 'https://player.vimeo.com/video/819056682?h=82d77a98c1'
          : 'https://player.vimeo.com/video/819080821?h=879836c378',

        native:
          'https://player.vimeo.com/progressive_redirect/playback/819056682/rendition/1080p/file.mp4?loc=external&signature=d8e495402a6a31aa7ceb19f1e3bf528c16639be59bf848795cd5418d54f87d0f',
      })}
    />
  );
});

export const LoyaltyVideoPreview = React.memo(() => {
  const isMobile = useIsMobile();

  const {source} = useIllustrationSource('illustration-4');

  return (
    <VideoPreview
      cover={source}
      videoUrl={Platform.select({
        web: isMobile
          ? 'https://player.vimeo.com/video/831417373'
          : 'https://player.vimeo.com/video/831478516',

        native:
          'https://player.vimeo.com/progressive_redirect/playback/819056682/rendition/1080p/file.mp4?loc=external&signature=d8e495402a6a31aa7ceb19f1e3bf528c16639be59bf848795cd5418d54f87d0f',
      })}
    />
  );
});
