import * as React from 'react';
import {Box} from '@youtoken/ui.primitives';
import {InputLikeWrapperProps} from './types';
import {useInputStyleProps} from './styles';

export type {InputLikeWrapperProps};

export const InputLikeWrapper: React.FC<InputLikeWrapperProps> = ({
  children,
  LeftPartComponent,
  RightPartComponent,
  ...props
}) => {
  const {px, ...styleProps} = useInputStyleProps(props);
  return (
    <Box
      flexDirection="row"
      accessible={false}
      borderRadius={6}
      borderWidth={1}
      pl={LeftPartComponent ? 0 : px}
      pr={RightPartComponent ? 0 : px}
      {...styleProps}
    >
      {LeftPartComponent && (
        <Box flexGrow={0} flexShrink={0}>
          {typeof LeftPartComponent === 'function' && (
            <LeftPartComponent
              hasError={props.hasError}
              disabled={props.disabled}
              focused={props.focused}
              size={props.size}
            />
          )}
          {React.isValidElement(LeftPartComponent) && LeftPartComponent}
        </Box>
      )}

      <Box
        flexGrow={1}
        flexShrink={1}
        // pl={LeftPartComponent ? 0 : 8} // NOTE: this is removed, as there are no extra paddings in design
        // pr={RightPartComponent ? 0 : 8} // NOTE: this is removed, as there are no extra paddings in design
      >
        {children}
      </Box>

      {RightPartComponent && (
        <Box flexGrow={0} flexShrink={0}>
          {typeof RightPartComponent === 'function' && (
            <RightPartComponent
              hasError={props.hasError}
              disabled={props.disabled}
              focused={props.focused}
              size={props.size}
            />
          )}

          {React.isValidElement(RightPartComponent) && RightPartComponent}
        </Box>
      )}
    </Box>
  );
};

InputLikeWrapper.defaultProps = {
  size: 'large',
};
