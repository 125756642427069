import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {type BoxProps, Box, Text} from '@youtoken/ui.primitives';
import {AccountLevelProgressBarDetailedCloseTradePerksIcon} from './Icon';

export const AccountLevelProgressBarDetailedTradePerksClosed: React.FC<BoxProps> =
  observer(boxProps => {
    const {t} = useTranslation();

    return (
      <Box gap={12} {...boxProps}>
        <Text variant="$body-02-high-accent" color="$text-01">
          {t('surface.loyalty.progress_bar_detailed.perks.closed_trade')}
        </Text>
        <Box flexDirection="row" alignItems="flex-start" gap={12}>
          <AccountLevelProgressBarDetailedCloseTradePerksIcon />
          <Box flex={1} alignItems="flex-start">
            <Text variant="$body-02" color="$text-02">
              {t(
                'surface.loyalty.progress_bar_detailed.perks.closed_trade_description'
              )}
            </Text>
          </Box>
        </Box>
      </Box>
    );
  });
