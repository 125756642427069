import * as React from 'react';
import {Box, Theme} from '@youtoken/ui.primitives';
import {IProgressBarProps} from '../types';

export const Wrapper: React.FC<IProgressBarProps> = ({
  children,
  size,
  color,
  isIndeterminate,
}) => {
  return (
    <Box
      position="relative"
      height={size === 'tiny' ? 4 : 24}
      overflow="hidden"
      // @ts-ignore reason: we need to ignore animated element it alway generates diff - https://www.chromatic.com/docs/ignoring-elements
      dataSet={
        isIndeterminate
          ? {
              chromatic: 'ignore',
            }
          : undefined
      }
      backgroundColor={
        (
          {
            interactive: '$interactive-03',
            attention: '$attention-03',
            success: '$success-03',
            danger: '$danger-03',
          } as {[key in IProgressBarProps['color']]: keyof Theme['colors']}
        )[color]
      }
      borderRadius={size === 'tiny' ? 2 : 6}
    >
      {children}
    </Box>
  );
};
