import * as React from 'react';
import {observer} from 'mobx-react';
import {LoyaltyProgressBarLevel} from '@youtoken/ui.resource-loyalty';
import {type BoxProps, Box, Text} from '@youtoken/ui.primitives';
import {AccountLevelProgressBarSparks} from './Sparks';

export type AccountLevelProgressBarFooterProps = {
  sparks: number;
  progressBarLevels: LoyaltyProgressBarLevel[];
};

export const AccountLevelProgressBarFooter: React.FC<
  AccountLevelProgressBarFooterProps & BoxProps
> = observer(
  ({
    sparks,
    progressBarLevels: [{level: firstLevel}, {level: lastLevel}],
    ...boxProps
  }) => {
    return (
      <Box
        testID="ACCOUNT_LEVEL_FORECAST_FOOTER"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="flex-start"
        {...boxProps}
      >
        <Box flex={1} alignItems="flex-start">
          <Text
            testID="ACCOUNT_LEVEL_FORECAST_FIRST_LEVEL"
            variant="$body-03"
            color="$text-02"
            textAlign="left"
            m={2}
          >
            {firstLevel.name}
          </Text>
        </Box>
        <AccountLevelProgressBarSparks
          flexBasis={{default: 140, tablet: 180}}
          value={sparks}
        />
        <Box flex={1} alignItems="flex-end">
          <Text
            testID="ACCOUNT_LEVEL_FORECAST_LAST_LEVEL"
            variant="$body-03"
            color="$text-02"
            textAlign="right"
            m={2}
          >
            {lastLevel.name}
          </Text>
        </Box>
      </Box>
    );
  }
);
