import * as React from 'react';
import Big from 'big.js';
import {TextInput, TextInputProps} from '../TextInput';
import {Box, Text} from '@youtoken/ui.primitives';
import {normalizeAmountWithPrecision} from '@youtoken/ui.normalizers';
import {warning} from '@youtoken/ui.utils';
import {Button} from '@youtoken/ui.buttons';
import {useInputStyleProps} from '../InputLikeWrapper/styles';

interface PercentageTextInputProps extends TextInputProps {
  signIsActive?: boolean;
  initiallyIsNegative?: boolean; // only for cases when signIsActive = false and no initial value set
  precision?: number;
}

export const PercentageTextInput: React.FC<PercentageTextInputProps> = ({
  value,
  signIsActive = false,
  initiallyIsNegative = false,
  onChangeText,
  precision = 2,
  setCaretPositionToEndOnFocus = true,
  ...textInputProps
}) => {
  const {px} = useInputStyleProps(textInputProps);

  // text part of value
  const absoluteValue = value?.replace(/[-]+/g, '');

  // sign part of value
  const isNegative = React.useMemo(() => {
    if (value === '') {
      return false;
    }
    try {
      return value?.includes('-') || Big(value).lt(0); // NOTE: Big('-0').lt(0) returns false
    } catch (e) {
      warning(
        false,
        'cannot convert ${value} to big number',
        {},
        {
          value,
        }
      );

      return initiallyIsNegative || value?.includes('-');
    }
  }, [value]);

  const [isNegativeLocal, setIsNegativeLocal] = React.useState(isNegative);

  React.useEffect(() => {
    setIsNegativeLocal(isNegative);
  }, [isNegative]);

  const handleChangeText = React.useCallback(
    (value: string) => {
      const normalizedValue = normalizeAmountWithPrecision(value, precision);

      onChangeText?.(`${isNegativeLocal ? '-' : ''}${normalizedValue}`);
    },
    [precision, isNegativeLocal, onChangeText]
  );

  const handleChangeNegative = React.useCallback(() => {
    if (signIsActive) {
      setIsNegativeLocal(prev => !prev);
      if (Number(absoluteValue) > 0) {
        // NOTE: change sign to opposite
        onChangeText?.(`${isNegativeLocal ? '' : '-'}${absoluteValue}`);
      }
    }
  }, [signIsActive, absoluteValue, isNegativeLocal, onChangeText]);

  const RightPartComponent = React.useMemo(() => {
    return signIsActive ? (
      <Box
        flexDirection="row"
        alignItems="center"
        justifyContent="center"
        height="100%"
        pr={px}
      >
        <Button
          icon={isNegativeLocal ? 'down' : 'up'}
          type="secondary"
          size="tiny"
          onPress={handleChangeNegative}
          testID={isNegativeLocal ? 'DOWN_BUTTON' : 'UP_BUTTON'}
        />
      </Box>
    ) : undefined;
  }, [signIsActive, handleChangeNegative, isNegativeLocal]);

  return (
    <Box position="relative">
      <TextInput
        value={absoluteValue}
        placeholder="0.00 %"
        // @ts-ignore
        minWidth={45} // NOTE: fix for Android placeholder
        maxLength={12}
        textAlign="left"
        autoComplete="off"
        autoCorrect={false}
        autoCapitalize="none"
        importantForAutofill="no"
        keyboardType="decimal-pad"
        onChangeText={handleChangeText}
        setCaretPositionToEndOnFocus={setCaretPositionToEndOnFocus}
        LeftPartComponent={
          <Box
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
            width={8}
            height="100%"
            ml={px}
            mr={2}
          >
            <Text variant="$body-01" color="$text-02">
              {isNegativeLocal ? '-' : '+'}
            </Text>
          </Box>
        }
        RightPartComponent={RightPartComponent}
        UnderlayInputElement={
          <Box
            flexDirection="row"
            alignItems="center"
            position="absolute"
            top={0}
            left={0}
            height="100%"
            pl={4}
            zIndex={0}
          >
            <Text
              variant="$body-01"
              color="$transparent"
              selectable={false}
              accessible={false}
              suppressHighlighting
            >
              {absoluteValue}
            </Text>
            <Text variant="$body-01" color="$text-02">
              {Boolean(absoluteValue) && '%'}
            </Text>
          </Box>
        }
        {...textInputProps}
      />
    </Box>
  );
};
