import * as React from 'react';
import {
  type TColorTokensNames,
  type BoxProps,
  Box,
  Text,
} from '@youtoken/ui.primitives';
import {type BaseIconName, Icon} from '@youtoken/ui.icons';

interface AccountLevelProgressBarDetailedPerksInfoItemProps {
  icon: BaseIconName;
  title: string;
  description: string;
  value: string | number;
  colors: Record<'primary' | 'secondary', TColorTokensNames>;
}

export const AccountLevelProgressBarDetailedPerksInfoItem: React.FC<
  AccountLevelProgressBarDetailedPerksInfoItemProps & BoxProps
> = ({
  value,
  colors: {primary: colorPrimary, secondary: colorSecondary},
  title,
  description,
  icon,
  ...boxProps
}) => {
  return (
    <Box
      flex={1}
      alignItems="flex-start"
      p={16}
      borderWidth={1}
      borderColor="$ui-01"
      borderRadius={12}
      {...boxProps}
    >
      <Text variant="$body-02-high-accent" color={colorPrimary}>
        {title}
      </Text>
      <Box
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
        px={8}
        py={6}
        mt={8}
        backgroundColor={colorSecondary}
        borderRadius={12}
      >
        <Icon size={16} name={icon} color={colorPrimary} />
        <Text variant="$body-02-high-accent" color={colorPrimary}>
          +{value}
        </Text>
      </Box>
      <Text variant="$body-02" color="$text-02" mt={6}>
        {description}
      </Text>
    </Box>
  );
};
