import * as React from 'react';
import {TouchableOpacity} from 'react-native';
import {Text, Box} from '@youtoken/ui.primitives';
import {Icon} from '@youtoken/ui.icons';
import {DropdownItem, DropdownItemData} from './DropdownItem';

export interface DropdownWebProps {
  initialItem: DropdownItemData;
  items: DropdownItemData[];
  onChange: (value: string) => void;
  testID?: string;
}

export const DropdownComponent: React.FC<DropdownWebProps> = ({
  initialItem,
  items,
  onChange,
  testID,
}) => {
  const modalRef = React.useRef<HTMLDivElement>(null);
  const triggerRef = React.useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = React.useState(false);
  const [selectedItem, setSelectedItem] = React.useState(initialItem);
  const isOpenRef = React.useRef(isOpen);

  React.useEffect(() => {
    isOpenRef.current = isOpen;
  }, [isOpen]);

  const handleToggle = () => {
    setIsOpen((prevState: boolean) => !prevState);
  };

  const handleChoose = React.useCallback(
    (item: DropdownItemData) => {
      setSelectedItem(item);
      setIsOpen(false);
      onChange(item.value);
    },
    [onChange]
  );

  const handleClickOutside = React.useCallback((event: MouseEvent) => {
    if (!modalRef.current || !triggerRef.current || !isOpenRef.current) {
      return;
    }

    if (
      !modalRef.current.contains(event.target as Node) &&
      !triggerRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  }, []);

  React.useEffect(() => {
    document.addEventListener('click', handleClickOutside, false);
    return () => {
      document.removeEventListener('click', handleClickOutside, false);
    };
  }, []);

  return (
    <Box position="relative" zIndex={isOpen ? 1000 : 0}>
      <Box
        // @ts-ignore
        ref={triggerRef}
        position="relative"
        zIndex={isOpen ? 2 : 1}
      >
        <TouchableOpacity onPress={handleToggle} testID={testID}>
          <Box flexDirection="row" alignItems="center">
            <Text color="$text-05" variant="$body-02">
              {selectedItem.text}
            </Text>
            <Box
              style={{
                transform: isOpen ? [{rotateX: '0'}] : [{rotateX: '180deg'}],
              }}
            >
              <Icon name="collapse" color="$interactive-01" />
            </Box>
          </Box>
        </TouchableOpacity>
      </Box>

      {isOpen && (
        <Box
          // @ts-ignore
          ref={modalRef}
          position="absolute"
          left={-15}
          top={-15}
          backgroundColor="$ui-background"
          p={5}
          my={-1}
          zIndex={10000}
          style={{
            shadowColor: 'rgba(0, 0, 0, 0.12)',
            shadowRadius: 30,
            shadowOffset: {width: 0, height: 5},
            borderColor: 'rgba(0, 0, 0, 0.1)',
          }}
          borderRadius={10}
        >
          {items.map((item: DropdownItemData, index: number) => (
            <DropdownItem
              key={index}
              {...item}
              isActive={item.value === selectedItem.value}
              onChoose={handleChoose}
            />
          ))}
        </Box>
      )}
    </Box>
  );
};
