import React from 'react';
import {
  Box,
  BoxProps,
  Heading,
  Text,
  TouchableBox,
} from '@youtoken/ui.primitives';
import {SavingsProgressBar} from '@youtoken/ui.progress-bar';
import {Button} from '@youtoken/ui.buttons';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {DATA_LAYER} from '@youtoken/ui.service-data-layer';
import {RewardType} from '@youtoken/ui.resources-rewards';

interface BonusProps {
  id: string;
  title: string;
  dealsTotal?: number;
  dealsCompleted?: number;
  description: string;
  amount: string;
  disabled?: boolean;
  bonusEarned?: boolean;
  type?: RewardType;
}

export const Bonus: React.FC<BonusProps & BoxProps> = ({
  id,
  title,
  dealsCompleted,
  dealsTotal,
  description,
  amount,
  disabled,
  bonusEarned = false,
  type,
  ...rest
}) => {
  const handlePress = React.useCallback(() => {
    if (disabled) {
      return;
    }

    SHARED_ROUTER_SERVICE.navigate('RewardDetails', {id});
    DATA_LAYER.trackStrict('reward-bonus-click', {bonusName: type});
  }, [id, disabled]);

  const bonusEarnedAndNotDisabled = !disabled && bonusEarned;

  return (
    <TouchableBox
      disabled={disabled}
      onPress={handlePress}
      bg={
        disabled
          ? '$ui-01'
          : bonusEarnedAndNotDisabled
          ? '$success-03'
          : '$fill-02'
      }
      borderRadius={16}
      overflow="hidden"
      padding={24}
      {...rest}
    >
      <Box flexDirection="row">
        <Box mr={16} flex={1}>
          <Heading variant="$heading-02" color="$text-01" mb={4}>
            {title}
          </Heading>
          <Text variant="$body-01" color="$text-01">
            {description}
          </Text>
        </Box>
        <Box>
          {disabled ? (
            <Button
              bg="$text-02"
              pressable={false}
              icon={bonusEarnedAndNotDisabled ? 'success' : 'gift'}
              type="primary"
              color={bonusEarnedAndNotDisabled ? 'success' : 'interactive'}
            >
              {amount}
            </Button>
          ) : (
            <Button
              icon={bonusEarnedAndNotDisabled ? 'success' : 'gift'}
              type="primary"
              color={bonusEarnedAndNotDisabled ? 'success' : 'interactive'}
              onPress={handlePress}
            >
              {amount}
            </Button>
          )}
        </Box>
      </Box>
      {dealsTotal > 1 && (
        <Box marginTop={16}>
          <Box>
            <SavingsProgressBar
              backgroundColor="$ui-background"
              color={bonusEarnedAndNotDisabled ? 'green' : 'blue'}
              size="tiny"
              value={dealsCompleted / dealsTotal}
            />
          </Box>
          <Box marginTop={8} alignItems="flex-end">
            <Text
              variant="$body-02-high-accent"
              color={
                bonusEarnedAndNotDisabled
                  ? '$success-01'
                  : dealsCompleted > 0
                  ? '$text-05'
                  : '$text-02'
              }
            >
              {dealsCompleted}
              {' / '}
              {dealsTotal}
            </Text>
          </Box>
        </Box>
      )}
    </TouchableBox>
  );
};
