import {action, computed} from 'mobx';
import {computedFn} from 'mobx-utils';
import {deserialize} from 'serializr';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {createStaticResource} from '@youtoken/ui.data-storage';
import {MinerUnauthorisedBlockData, mockBlocksData} from '../assets';
import {MinerBlockData, MinerOverviewResponse} from './MinerOverviewResponse';
import {type MinerOverviewResourceArgs, type FEBlockState} from './types';
import {createEmptyDisabledBlock} from './utils';

const navigateToSighUp = (_blockId: string) => {
  SHARED_ROUTER_SERVICE.navigate('SignUp');
};

// NOTE: I left blockId props for type compatibility with MinerOverviewResource
const emptyFn = (_blockId: string) => {};

export interface MinerUnauthorisedBlock extends Omit<MinerBlockData, 'status'> {
  status: FEBlockState; // cant be used BE statuses
}

export class MinerOverviewUnauthorisedResource extends createStaticResource<
  MinerOverviewResourceArgs,
  MinerOverviewResponse
>({
  getKey: () => `minerOverviewUnauthorised`,
  /** Get data with shuffled coordinates to add at least a little of interactivity to miner blocks
   * The Fisher–Yates shuffle algorithm was used, see https://bost.ocks.org/mike/shuffle/
   * */
  getData: () => {
    let currentIndex: number = mockBlocksData.length;
    let randomIndex: number;

    while (currentIndex > 0) {
      // Pick a remaining element.
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // And swap it with the current element.
      [
        mockBlocksData[currentIndex].coordinates,
        mockBlocksData[randomIndex].coordinates,
      ] = [
        mockBlocksData[randomIndex].coordinates,
        mockBlocksData[currentIndex].coordinates,
      ];

      if (mockBlocksData[currentIndex].status === 'DISABLED') {
        mockBlocksData[currentIndex] = createEmptyDisabledBlock(
          mockBlocksData[currentIndex].coordinates
        ) as Partial<MinerUnauthorisedBlockData>;
      }
    }

    return Promise.resolve(
      deserialize(MinerOverviewResponse, {
        blocks: mockBlocksData,
        settings: {hodlSparksRequiredVolume: 1000, hodlSparksAmount: 1},
      })
    );
  },
}) {
  @computed.struct
  get blocks() {
    return this.data.blocks;
  }

  @action startMining = navigateToSighUp;
  @action startClaiming = navigateToSighUp;
  @action showClaimedInfo = navigateToSighUp;
  @action onTimerEnd = emptyFn;
  @action onClaimBounceEnd = emptyFn;
  @action onClaimFlyOffEnd = emptyFn;
  @action onGreyBounceEnd = emptyFn;

  getBlockFinalStatus = computedFn((blockId: string): FEBlockState => {
    // NOTE: Type assertion, because unauthorisedMinerBlocksData which form the blocks also have statuses with FEBlockState type
    const block = this.blocks.find(
      block => block.id === blockId
    ) as MinerUnauthorisedBlock;
    return block.status;
  });
}
