import * as React from 'react';
import {observer} from 'mobx-react';
import {DATA_LAYER} from '@youtoken/ui.service-data-layer';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {TouchableBox, Box, Text} from '@youtoken/ui.primitives';
import {Icon} from '@youtoken/ui.icons';
import {ReferralsRoundedText} from '..';
import {ReferralsWidgetUserIcon} from './UserIcon';

interface ReferralsWidgetProps {
  header: React.ReactNode;
  referralsCount: number;
  totalEarnedUSD: number;
  newEarnedUSD?: number;
}

export const ReferralsWidget: React.FC<ReferralsWidgetProps> = observer(
  ({header, referralsCount, newEarnedUSD, totalEarnedUSD}) => {
    const {t} = useTranslation();

    const referralsList = React.useMemo(() => {
      return Array.from({length: 5}).map((i, index) => ({
        active: index < referralsCount,
      }));
    }, [referralsCount]);

    const handlePress = React.useCallback(() => {
      SHARED_ROUTER_SERVICE.navigate('RewardsReferrals');
      DATA_LAYER.trackStrict('reward-referral-click', {});
    }, []);

    return (
      <TouchableBox
        onPress={handlePress}
        backgroundColor="$interactive-01"
        p={24}
        borderRadius={16}
        position="relative"
        overflow="hidden"
      >
        <Box position="absolute" left={-30} top={-30} zIndex={-1} opacity={0.2}>
          <Icon name="link" size={202} color="$interactive-04" />
        </Box>
        <Box flexDirection="row" justifyContent="space-between">
          <Box flexGrow={1} flexShrink={1}>
            {header}
          </Box>
          <Box flexDirection="row" flexWrap="nowrap" pl={6} ml={8}>
            {referralsList.map((item, index) => (
              <ReferralsWidgetUserIcon
                key={index}
                active={item.active}
                ml={-6}
                zIndex={-index}
              />
            ))}
          </Box>
        </Box>
        <Box
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          mt={24}
        >
          <ReferralsRoundedText bg="$text-04" textColor="$text-05">
            ${totalEarnedUSD}
          </ReferralsRoundedText>
          <Box flexDirection="row" flexWrap="nowrap" alignItems="center" ml={6}>
            <Text variant="$body-02" color="$text-04">
              {t('surface.referral_program.referrals_and_bonuses.joined')}
            </Text>
            <ReferralsRoundedText ml={6} bg="$fill-03" textColor="$text-04">
              {referralsCount}
            </ReferralsRoundedText>
            {newEarnedUSD > 0 && (
              <ReferralsRoundedText
                bg="$success-01"
                textColor="$text-04"
                ml={8}
              >
                +${newEarnedUSD}
              </ReferralsRoundedText>
            )}
          </Box>
        </Box>
      </TouchableBox>
    );
  }
);
