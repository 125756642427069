import * as React from 'react';
import {ListRenderItem} from 'react-native';
import {observer} from 'mobx-react';
import {RewardsHistoryItemV1} from '@youtoken/ui.resources-rewards';
import {Separator} from '@youtoken/ui.elements';
import {RewardsHistoryProps} from '../types';
import {keyExtractor} from '../utils';
import {BottomSheetFlatList, RewardsHistoryTitle} from '../..';
import {RewardsHistoryItemV1Item} from '../Item';

const renderItem: ListRenderItem<RewardsHistoryItemV1> = ({item}) => {
  return <RewardsHistoryItemV1Item data={item} />;
};

export interface RewardsHistoryFlatListV1Props extends RewardsHistoryProps {
  items: RewardsHistoryItemV1[];
}

export const RewardsHistoryFlatListV1: React.FC<RewardsHistoryFlatListV1Props> =
  observer(({showHeader, items}) => {
    return (
      <BottomSheetFlatList
        ListHeaderComponent={showHeader && RewardsHistoryTitle}
        ItemSeparatorComponent={Separator}
        style={{flex: 1}}
        data={items}
        renderItem={renderItem}
        keyExtractor={keyExtractor}
      />
    );
  });
