import * as React from 'react';
import {TwoFactorAuthVerification} from './TwoFactorAuthVerification';
import {TwoFactorAuthParams} from './types';

export const TwoFactorAuthContext = React.createContext<{
  requestTwoFactorAuth: (params: TwoFactorAuthParams) => Promise<string>;
}>({
  requestTwoFactorAuth: () =>
    Promise.reject(new Error('TwoFactorAuthContext not initialized')),
});

export const TwoFactorAuthContextProvider: React.FC = ({children}) => {
  const twoFactorAuthRef = React.useRef<TwoFactorAuthVerification>(null);

  const value = React.useMemo<{
    requestTwoFactorAuth: (params: TwoFactorAuthParams) => Promise<string>;
  }>(() => {
    function rejectIfNotInitialized() {
      if (!twoFactorAuthRef.current) {
        return Promise.reject(new Error('TwoFactorAuth not initialized!'));
      }
    }

    return {
      requestTwoFactorAuth: (params: TwoFactorAuthParams) => {
        rejectIfNotInitialized();

        return twoFactorAuthRef.current?.requestTwoFactorAuth(params);
      },
    };
  }, []);

  return (
    <TwoFactorAuthContext.Provider value={value}>
      <TwoFactorAuthVerification ref={twoFactorAuthRef} />
      {children}
    </TwoFactorAuthContext.Provider>
  );
};

export const useTwoFactorAuth = () => {
  const context = React.useContext(TwoFactorAuthContext);

  if (!context) {
    throw new Error(
      'useTwoFactorAuth must be used within a TwoFactorAuthContextProvider'
    );
  }

  return context;
};
