import * as React from 'react';
import {
  type NotificationModalProps,
  NotificationModal,
} from './NotificationModal';

export const NotificationContext = React.createContext<NotificationModalProps>({
  request: () => {
    new Error('ConfirmationContext not initialized');
  },
});

export const NotificationContextProvider: React.FC = ({children}) => {
  const modalNotificationRef = React.useRef<NotificationModalProps>(null);

  const value = React.useMemo(() => {
    return {
      request: modalNotificationRef.current?.request,
    };
  }, []);

  return (
    <NotificationContext.Provider value={value}>
      <NotificationModal ref={modalNotificationRef} />
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotification = () => {
  const context = React.useContext(NotificationContext);

  if (!context) {
    throw new Error(
      'useNotification must be used within a NotificationContextProvider'
    );
  }

  return context;
};
