import {RewardStatus} from '@youtoken/ui.resource-rewards-v3';
import {type BadgeProps} from '@youtoken/ui.elements';
import {RewardsLevelsVariant} from '../types';

export const getActiveIndicatorColor = (status: RewardStatus, variant) => {
  if (status === RewardStatus.READY || status === RewardStatus.LAST_READY) {
    return '$success-01';
  }

  return variant === RewardsLevelsVariant.COMPLETED
    ? '$ui-01'
    : '$interactive-01';
};

export const getBadgeIconByStatus = (status: RewardStatus) => {
  if (status === RewardStatus.READY || status === RewardStatus.LAST_READY) {
    return 'success';
  }

  return 'gift';
};

export const getBadgePropsCompleted = (status: RewardStatus): BadgeProps => {
  if (status === RewardStatus.READY) {
    return {
      type: 'secondary',
      variant: 'success',
    };
  }

  if (status === RewardStatus.LAST_READY) {
    return {
      type: 'primary',
      variant: 'success',
    };
  }

  return {
    type: 'secondary',
    variant: 'neutral',
  };
};

export const getBadgePropsActive = (
  status: RewardStatus,
  active
): BadgeProps => {
  if (status === RewardStatus.READY || status === RewardStatus.LAST_READY) {
    return {
      type: 'primary',
      variant: 'success',
    };
  }

  if (active) {
    return {
      type: 'primary',
      variant: 'interactive',
    };
  }

  return {
    type: 'secondary',
    variant: 'success',
  };
};

export const getBadgeProps = (
  status: RewardStatus,
  variant,
  active: boolean
): BadgeProps => {
  const props =
    variant === RewardsLevelsVariant.COMPLETED
      ? getBadgePropsCompleted(status)
      : getBadgePropsActive(status, active);

  return {
    icon: getBadgeIconByStatus(status),
    ...props,
  };
};
