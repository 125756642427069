import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {type LevelStatus, LoyaltyResource} from '@youtoken/ui.resource-loyalty';
import {
  type BoxProps,
  type TextProps,
  Box,
  Text,
} from '@youtoken/ui.primitives';
import {Icon} from '@youtoken/ui.icons';
import {QuestionTooltip} from '@youtoken/ui.tooltip';
import {AccountLevelCurrent} from '../AccountLevelCurrent';

export interface AccountLevelRequirementsProps extends BoxProps {
  id: number;
}

export const AccountLevelRequirements: React.FC<AccountLevelRequirementsProps> =
  observer(({id}) => {
    const {t} = useTranslation();

    const {
      data: {currentLevel},
      getLevel,
    } = LoyaltyResource.use({});

    const {
      status,
      requiredDepositVisibleFormatted,
      requiredVolumeFormattedWithSeparators,
    } = getLevel(id);

    if (id === currentLevel) {
      return <AccountLevelCurrent />;
    }

    if (id === 1) {
      return (
        <LevelRequirementText status={status}>
          {t('surface.loyalty.next_level_requirement.1')}
        </LevelRequirementText>
      );
    }

    if (id === 2) {
      return (
        <LevelRequirementText status={status}>
          {t('surface.loyalty.next_level_requirement.2', {
            amount: requiredDepositVisibleFormatted,
          })}
        </LevelRequirementText>
      );
    }

    if (id === 3) {
      return (
        <LevelRequirementText status={status}>
          {t('surface.loyalty.next_level_requirement.3')}
        </LevelRequirementText>
      );
    }

    return (
      <Box flexDirection="column" alignItems="center">
        <Text variant="$body-02" color="$text-01" mb={4}>
          {t('surface.loyalty.required_volume')}
        </Text>
        <Box flexDirection="row" alignItems="center">
          <Icon name="volume" size={16} />
          <Text variant="$body-01-high-accent" mr={4}>
            {requiredVolumeFormattedWithSeparators}
          </Text>
          <QuestionTooltip
            content={t('surface.loyalty.required_volume_hint')}
          />
        </Box>
      </Box>
    );
  });

export interface LevelRequirementTextProps extends TextProps {
  status: LevelStatus;
}

export const LevelRequirementText: React.FC<LevelRequirementTextProps> = ({
  children,
  status,
  ...rest
}) => {
  return (
    <Text
      variant="$body-01"
      color={status === 'completed' ? '$text-02' : '$text-01'}
      {...rest}
    >
      {children}
    </Text>
  );
};
