import {computed, observable} from 'mobx';
import {serializable, alias, primitive, object, map, list} from 'serializr';
import {number} from '@youtoken/ui.utils-serializr';
import {formatPercent} from '@youtoken/ui.formatting-utils';
import {RewardsResource} from './RewardsResource';

export enum RewardOverviewStatus {
  OPEN = 'OPEN',
  PENDING = 'PENDING',
  READY = 'READY',
  CLAIMED = 'CLAIMED',
  BANNED = 'BANNED',
  ERROR = 'ERROR',
  DECLINED = 'DECLINED',
}

export enum RewardStatus {
  READY = 'READY',
  LAST_READY = 'LAST_READY',
  PROCESSING = 'PROCESSING',
  OPEN = 'OPEN',
}

export class RewardLevel {
  @serializable(number())
  @observable
  level: number;

  @serializable(number())
  @observable
  amount: number;

  @serializable(primitive())
  @observable
  currencyTicker: string;

  @computed
  get currentDeposit() {
    const {
      data: {currentDeposit},
    } = RewardsResource.__DANGEROUSLY__getInstanceStatically({});

    return Math.min(currentDeposit, this.requiredDeposit);
  }

  @serializable(alias('depositVolume', number()))
  @observable
  requiredDeposit: number;

  @computed
  get currentVolume() {
    const {
      data: {currentVolume},
    } = RewardsResource.__DANGEROUSLY__getInstanceStatically({});

    return Math.min(currentVolume, this.requiredVolume);
  }

  @serializable(alias('hodlVolume', number()))
  @observable
  requiredVolume: number;

  @serializable(primitive())
  @observable
  status: RewardStatus;

  @computed
  get isFirst() {
    const {
      data: {levels},
    } = RewardsResource.__DANGEROUSLY__getInstanceStatically({});

    return this.level === levels[0].level;
  }

  @computed
  get isLast() {
    const {
      data: {levels},
    } = RewardsResource.__DANGEROUSLY__getInstanceStatically({});

    return this.level === levels[levels.length - 1].level;
  }
}

export class RewardOverview {
  @serializable(number())
  newsCount: number;

  @serializable(number())
  maxEarningsUSD: number;

  @serializable(number())
  newEarnedUSD: number;

  @serializable(number())
  totalEarnedUSD: number;

  @serializable(number())
  totalEarnedReferralUSD: number;

  @serializable(number())
  referralsCount: number;

  @serializable(number())
  maxEarningsPercent: number;

  @computed
  get maxEarningsPercentFormatted() {
    return formatPercent(this.maxEarningsPercent);
  }

  @serializable(primitive())
  @observable
  status: RewardOverviewStatus;

  @serializable(list(object(RewardLevel)))
  @observable
  levels: RewardLevel[];

  @serializable(alias('currentDepositVolume', number()))
  @observable
  currentDeposit: number;

  @serializable(alias('currentHodlVolume', number()))
  @observable
  currentVolume: number;

  @serializable(number())
  timeLeft: number;

  @computed
  get processingLevel() {
    return this.levels.find(level => {
      return level.status === RewardStatus.PROCESSING;
    });
  }

  @computed
  get completedLevel() {
    return this.levels.find(level => {
      return level.status === RewardStatus.LAST_READY;
    });
  }

  @computed
  get completedLevelNumber() {
    return this.completedLevel?.level;
  }

  @computed
  get initialLevel() {
    const completedLevelOrFirst = this.completedLevel ?? this.levels[0];

    if (this.status === RewardOverviewStatus.OPEN) {
      return this.processingLevel ?? completedLevelOrFirst;
    }

    return completedLevelOrFirst;
  }

  @computed
  get initialLevelNumber() {
    return this.initialLevel.level;
  }

  @computed
  get completedLevelAmount() {
    return this.completedLevel?.amount ?? 0;
  }

  @computed
  get lastLevel() {
    return this.levels[this.levels.length - 1];
  }

  @computed
  get lastLevelAmount() {
    return this.lastLevel.amount;
  }
}
