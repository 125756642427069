import * as React from 'react';
import {cell} from '@youtoken/ui.cell';
import {Modal} from '@youtoken/ui.modal';
import {
  type AccountLevelProgressDetailedProps,
  getAccountLevelProgressDetailedTitle,
  AccountLevelProgressDetailed,
} from '../../../smarts';

export const AccountLevelProgressDetailedModal: React.FC<
  AccountLevelProgressDetailedProps & React.ComponentProps<typeof Modal>
> = cell(({variant, volume, sparks, ...modalProps}) => {
  return (
    <Modal
      testID="ACCOUNT_LEVEL_PROGRESS_DETAILED_MODAL"
      title={getAccountLevelProgressDetailedTitle()}
      {...modalProps}
    >
      <AccountLevelProgressDetailed
        variant={variant}
        volume={volume}
        sparks={sparks}
      />
    </Modal>
  );
});
