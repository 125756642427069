import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {type BoxProps, Box} from '@youtoken/ui.primitives';
import {PromoBanner} from '@youtoken/ui.info-components';
import {AccountLevelsHowItWorksModal} from './Modal';

export const AccountLevelsHowItWorks: React.FC<BoxProps> = observer(
  boxProps => {
    const {t} = useTranslation();

    const {
      data: {
        clientSettings: {enableLoyaltyHowItWorksBanner},
      },
      setLoyaltyHowItWorksBannerEnabled,
    } = AuthMeResource.use({});

    const [isOpen, setIsOpen] = React.useState(false);

    const toggleIsOpen = React.useCallback(() => {
      setIsOpen(isOpen => !isOpen);
    }, []);

    const handlePressCloseBanner = React.useCallback(() => {
      setLoyaltyHowItWorksBannerEnabled(false);
    }, [setLoyaltyHowItWorksBannerEnabled]);

    if (!enableLoyaltyHowItWorksBanner) {
      return null;
    }

    return (
      <Box testID="ACCOUNT_LEVELS_HOW_IT_WORKS" {...boxProps}>
        <PromoBanner
          testID="ACCOUNT_LEVELS_HOW_IT_WORKS_BANNER"
          imageName="loyalty"
          text={t('surface.loyalty.how_it_works_banner.text')}
          onPressBanner={toggleIsOpen}
          onPressClose={handlePressCloseBanner}
        />
        <AccountLevelsHowItWorksModal isOpen={isOpen} onClose={toggleIsOpen} />
      </Box>
    );
  }
);
