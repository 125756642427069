import * as React from 'react';
import {formatBigNumber} from '@youtoken/ui.formatting-utils';
import {Heading, Text, Box, type BoxProps} from '@youtoken/ui.primitives';
import {currencyName, currencySignMap} from '@youtoken/ui.resource-auth-me';
import {MainCurrencySelector} from '../MainCurrencySelector';

export interface TotalInfoElementProps {
  text: string | JSX.Element;
  currency: currencyName;
  currencyAmount: string;
}

export const Total: React.FC<TotalInfoElementProps & BoxProps> = ({
  text,
  currency,
  currencyAmount,
  ...boxProps
}) => {
  const currencySign = currencySignMap[currency];

  return (
    <Box flex={1} flexShrink={0} testID="TOTAL_INVESTED_FIELD" {...boxProps}>
      <Box flexDirection="row" alignItems="center" height={24}>
        {typeof text === 'string' ? (
          <Text color="$text-02" variant="$body-02" mr={4}>
            {text}
          </Text>
        ) : (
          <Box flexDirection="row" alignItems="center" mr={4}>
            {text}
          </Box>
        )}
        <MainCurrencySelector />
      </Box>
      <Box zIndex={-1}>
        <Heading variant="$heading-01-responsive" testID="TOTAL_FUNDS">
          {currencySign}
          {currencyAmount}
        </Heading>
      </Box>
    </Box>
  );
};
