import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {useResources, getResourceDescriptor} from '@youtoken/ui.data-storage';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {LoyaltyResource} from '@youtoken/ui.resource-loyalty';
import {MinerOverviewResource} from '@youtoken/ui.resources-miner';
import {type BoxProps, Box} from '@youtoken/ui.primitives';
import {type IconName} from '@youtoken/ui.icons/src/Icon';
import {AccountLevelProgressAction} from './Action';
import {AccountLevelProgressWidgetAuthorized} from '..';

export {AccountLevelProgressNoMiner} from './AccountLevelProgressNoMiner';

export interface AccountLevelProgressProps {
  TradeToUpgrade?: React.FC;
}

export const AccountLevelProgress: React.FC<
  AccountLevelProgressProps & BoxProps
> = observer(({TradeToUpgrade, ...boxProps}) => {
  const {t} = useTranslation();

  const {
    authMe: {
      products: {
        miner: {
          settings: {isOnboarding: isMinerOnboarding},
        },
      },
    },
    loyalty: {
      data: {currentLevel},
      getLevel,
    },
    minerOverview: {onboardingStep},
  } = useResources({
    authMe: getResourceDescriptor(AuthMeResource, {}),
    loyalty: getResourceDescriptor(LoyaltyResource, {}),
    minerOverview: getResourceDescriptor(MinerOverviewResource, {}),
  });

  const {requiredDepositVisibleFormatted} = getLevel(2);

  const openUpgradeLevelModal = React.useCallback(() => {
    SHARED_ROUTER_SERVICE.navigate('UpgradeYourLevel');
  }, []);

  const title = React.useMemo(() => {
    return (
      {
        1: t('surface.miner.onboarding.step1.title'),
        2: t('surface.miner.onboarding.step2.title'),
        3: t('surface.miner.onboarding.upgrade'),
        4: t('surface.miner.onboarding.step3.title'),
      }[onboardingStep] ?? t('surface.miner.loyalty.upgrade')
    );
  }, [onboardingStep, t]);

  const description = React.useMemo(() => {
    return (
      {
        1: t('surface.miner.onboarding.step1.description'),
        2: t('surface.miner.onboarding.step2.description'),
      }[onboardingStep] ?? ''
    );
  }, [onboardingStep, t]);

  const iconName = React.useMemo(() => {
    return {
      1: 'mining' as IconName,
      2: 'miner_block_cost' as IconName,
      3: 'miner_block_cost' as IconName,
      4: 'miner_block_cost' as IconName,
    }[onboardingStep];
  }, [onboardingStep]);

  return (
    <Box
      testID="ACCOUNT_LEVEL_DETAILED_WIDGET"
      p={{default: 16, tablet: 24}}
      pb={currentLevel > 2 ? 0 : undefined}
      bg="$ui-background"
      borderWidth={1}
      borderColor="$ui-01"
      borderRadius={16}
      {...boxProps}
    >
      {currentLevel === 1 && (
        <AccountLevelProgressAction
          onPress={
            [1, 2].includes(onboardingStep) ? undefined : openUpgradeLevelModal
          }
          title={title}
          description={description}
          iconName={iconName}
        >
          {isMinerOnboarding
            ? t('surface.loyalty.next_level.upgrade')
            : t('surface.loyalty.next_level_requirement.2', {
                amount: requiredDepositVisibleFormatted,
              })}
        </AccountLevelProgressAction>
      )}
      {currentLevel === 2 && (
        <AccountLevelProgressAction
          onPress={openUpgradeLevelModal}
          title={title}
          description={description}
          iconName={iconName}
        >
          {isMinerOnboarding
            ? t('surface.loyalty.next_level.upgrade')
            : t('surface.loyalty.next_level_requirement.3')}
        </AccountLevelProgressAction>
      )}
      {currentLevel > 2 && (
        <AccountLevelProgressWidgetAuthorized TradeToUpgrade={TradeToUpgrade} />
      )}
    </Box>
  );
});
