import {reaction, IReactionDisposer} from 'mobx';
import {computedFn} from 'mobx-utils';
import {deserialize, primitive, createSimpleSchema} from 'serializr';
import {createStaticResource} from '@youtoken/ui.data-storage';
import {TRANSPORT} from '@youtoken/ui.transport';
import {i18n} from '@youtoken/ui.service-i18n';

export enum LocaliseResourceNamespace {
  NOTIFICATION = 'notification',
  HODL = 'hodl',
}

export const LocaliseResponse = createSimpleSchema<Record<string, string>>({
  '*': primitive(),
});

export interface LocaliseResourceArgs {
  namespace: string;
  language?: string; // NOTE: the language param is needed only in unauthorized mode, in authorized mode the backend knows the language from the user settings
}

export class LocaliseResource extends createStaticResource<
  LocaliseResourceArgs,
  Record<string, string>
>({
  getKey: ({namespace, language}) =>
    `LocaliseResource(${namespace}, ${language})`,
  getData: ({namespace, language}) => {
    return TRANSPORT.API.get(`/v1/i18n/${namespace}`, {
      params: {
        language,
      },
    }).then(res => {
      return deserialize(LocaliseResponse, res.data);
    });
  },
}) {
  disposers: Array<IReactionDisposer> = [];

  translate = computedFn(i18nKey => {
    return this.data[i18nKey];
  });

  constructor(args, result) {
    super(args, result);

    this.disposers.push(
      reaction(
        () => i18n.language,
        () => {
          this.refetchSilently();
        },
        // NOTE: a short delay to be sure that backend received the request to change the language
        {
          delay: 500,
        }
      )
    );
  }

  onDestroy(): void {
    super.onDestroy();
    this.disposers.forEach(disposer => disposer?.());
  }
}
