import {computed} from 'mobx';
import {primitive, serializable} from 'serializr';
import {formatByTicker} from '@youtoken/ui.formatting-utils';
import {
  mainCurrencyVariants,
  MainCurrencyVariants,
} from '@youtoken/ui.utils-serializr';

export class RewardSummaryResponse {
  @serializable(mainCurrencyVariants())
  totalEarned: MainCurrencyVariants;

  @computed get earnedTotalUSD() {
    return '$' + formatByTicker(this.totalEarned?.usd, 'usd');
  }

  @computed get userEarnedSomething() {
    return this.totalEarned.usd.gt(0);
  }

  @serializable(mainCurrencyVariants())
  earnedReferral: MainCurrencyVariants;

  @serializable(mainCurrencyVariants())
  maxReferralEarning: MainCurrencyVariants;

  @computed get maxUserEarningUSD() {
    return '$' + formatByTicker(this.maxReferralEarning?.usd, 'usd');
  }

  @computed get earnedReferralUSD() {
    return '$' + formatByTicker(this.earnedReferral?.usd, 'usd');
  }

  @serializable(primitive())
  referralsCount: number;

  @computed get referralsList() {
    return Array.from({length: 5})
      .map((i, index) => ({
        joined: index < this.referralsCount,
      }))
      .reverse();
  }

  @serializable(primitive())
  newReferralEarningsCount: number = 0;

  @serializable(primitive())
  newsNumberReward: number = 0;
}
