import * as React from 'react';
import {observer} from 'mobx-react';
import {TouchableBox, Box, Text, type BoxProps} from '@youtoken/ui.primitives';
import {LoyaltyIcon, Icon} from '@youtoken/ui.icons';
import {LoyaltyResource} from '@youtoken/ui.resource-loyalty';
import {getResourceDescriptor, useResources} from '@youtoken/ui.data-storage';
import {MinerUpgradeRequirementsResource} from '@youtoken/ui.resources-miner';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {type BaseTabProps} from './BaseTab';

export type JumpToLevelProps = BaseTabProps;

export const JumpToLevel: React.FC<JumpToLevelProps & BoxProps> = observer(
  ({onActionPress, product, variant, ...boxProps}) => {
    const {
      minerUpgradeRequirements: {
        data: {
          [product]: {
            sparksAmount,
            loyalty: {multiplier, volume, volumeFormatted},
          },
        },
      },
      loyalty: {
        getLevel,
        data: {nextLevel},
      },
    } = useResources({
      minerUpgradeRequirements: getResourceDescriptor(
        MinerUpgradeRequirementsResource,
        {}
      ),
      loyalty: getResourceDescriptor(LoyaltyResource, {}),
    });

    const {t} = useTranslation();

    const levelData = getLevel(nextLevel);

    const accentColor =
      variant === 'sparks' ? '$attention-01' : '$interactive-01';

    const handlePress = () => {
      onActionPress(volume, multiplier);
    };

    if (!levelData) {
      return null;
    }

    return (
      <TouchableBox
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        px={16}
        py={18}
        borderRadius={12}
        borderColor={accentColor}
        borderWidth={1}
        onPress={handlePress}
        {...boxProps}
      >
        <Box flexDirection="row" flex={1}>
          <Box
            width={40}
            height={40}
            borderRadius={40}
            justifyContent="center"
            alignItems="center"
            backgroundColor={levelData.cardColor}
            mr={12}
          >
            <LoyaltyIcon
              name={levelData.cardIconName}
              color={accentColor}
              size={30}
            />
          </Box>

          <Box flexDirection="column" flex={1}>
            <Box flexDirection="row" alignItems="center" mb={3}>
              <Text variant="$body-02-high-accent">
                {t('surface.upgrade_modal.volume.jump', {
                  level: levelData.name,
                })}
              </Text>
              <Box
                flexDirection="row"
                backgroundColor="$attention-02"
                ml={6}
                px={6}
                py={2}
                borderRadius={24}
                alignItems="center"
              >
                <Icon name="spark" size={12} color="$attention-01" />
                <Text variant="$body-03-high-accent" color="$attention-01">
                  +{sparksAmount}
                </Text>
              </Box>
            </Box>

            <Text variant="$body-02" color="$text-02">
              {t(`surface.upgrade_modal.${product}_benefit`, {
                volumeEquivalent: volumeFormatted,
                multiplier,
              })}
            </Text>
          </Box>
        </Box>
        <Icon name="chevron_right" size={16} color={accentColor} />
      </TouchableBox>
    );
  }
);
