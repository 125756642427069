import * as React from 'react';
import {type TColorTokens, type BoxProps, Box} from '@youtoken/ui.primitives';
import {type BaseIconName, Icon} from '@youtoken/ui.icons';

type BaseIconColors = Record<'primary' | 'secondary', keyof TColorTokens>;

const VARIANTS: Partial<Record<BaseIconName, BaseIconColors>> = {
  volume: {
    primary: '$interactive-01',
    secondary: '$interactive-02',
  },
  spark: {
    primary: '$attention-01',
    secondary: '$attention-02',
  },
};

export const AccountLevelProgressBarDetailedCloseTradePerksIcon: React.FC =
  () => {
    return (
      <Box width={48} height={48}>
        <AccountLevelProgressBarDetailedCloseTradePerksIconSub
          name="volume"
          position="absolute"
          bottom={0}
          left={0}
        />
        <AccountLevelProgressBarDetailedCloseTradePerksIconSub
          name="spark"
          position="absolute"
          top={0}
          right={0}
        />
      </Box>
    );
  };

interface AccountLevelProgressBarDetailedCloseTradePerksIconSubProps {
  name: BaseIconName;
}

const AccountLevelProgressBarDetailedCloseTradePerksIconSub: React.FC<
  AccountLevelProgressBarDetailedCloseTradePerksIconSubProps & BoxProps
> = ({name, ...boxProps}) => {
  const {primary: colorPrimary, secondary: colorSecondary} = VARIANTS[name];

  return (
    <Box
      justifyContent="center"
      alignItems="center"
      width={32}
      height={32}
      backgroundColor={colorSecondary}
      borderWidth={2}
      borderColor="$ui-background"
      borderRadius={16}
      {...boxProps}
    >
      <Icon size={20} name={name} color={colorPrimary} />
    </Box>
  );
};
