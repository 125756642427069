import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {LoyaltyLevel} from '@youtoken/ui.resource-loyalty';
import {type BoxProps} from '@youtoken/ui.primitives';
import {AccountLevelProgressBarVariant} from '../../../types';
import {AccountLevelProgressBarDetailedPerksInfoItem} from './Item';

interface AccountLevelProgressBarDetailedPerksInfoItemProgressPointsProps {
  variant?: AccountLevelProgressBarVariant;
  level: LoyaltyLevel;
}

export const AccountLevelProgressBarDetailedPerksInfoItemProgressPoints: React.FC<
  AccountLevelProgressBarDetailedPerksInfoItemProgressPointsProps & BoxProps
> = observer(({variant, level, ...boxProps}) => {
  const {t} = useTranslation();

  return (
    <AccountLevelProgressBarDetailedPerksInfoItem
      icon="volume"
      title={t('surface.loyalty.common.progress_points')}
      description={t('surface.loyalty.common.progress_points_description', {
        amount:
          variant === AccountLevelProgressBarVariant.HODL
            ? 1
            : level.conversion.conversionRequiredVolumeFormatted,
      })}
      value={
        variant === AccountLevelProgressBarVariant.HODL
          ? 1
          : level.conversion.conversionVolumeFormatted
      }
      colors={{
        primary: '$interactive-01',
        secondary: '$interactive-02',
      }}
      {...boxProps}
    />
  );
});
