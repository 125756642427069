import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {type LoyaltyProgressBarLevel} from '@youtoken/ui.resource-loyalty';
import {type BoxProps, Box, Text} from '@youtoken/ui.primitives';
import {AccountLevelProgressBarDetailedNextGoalsLevel} from './Level';

export type AccountLevelProgressBarDetailedNextGoalsProps = {
  progressBarLevels: LoyaltyProgressBarLevel[];
};

export const AccountLevelProgressBarDetailedNextGoals: React.FC<
  AccountLevelProgressBarDetailedNextGoalsProps & BoxProps
> = observer(({progressBarLevels, ...boxProps}) => {
  const {t} = useTranslation();

  return (
    <Box gap={8} {...boxProps}>
      <Box
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box flexDirection="row" alignItems="center">
          <Text variant="$body-01-high-accent">
            {t('surface.loyalty.progress_bar_detailed.next_goals')}
          </Text>
        </Box>
        <Box flexDirection="row" alignItems="center">
          <Text variant="$body-02" color="$text-02">
            {t('surface.loyalty.common.progress_points')}
          </Text>
        </Box>
      </Box>
      <Box gap={8}>
        {progressBarLevels.map(progressBarLevel => {
          const {
            level: {name: key},
          } = progressBarLevel;

          return (
            <AccountLevelProgressBarDetailedNextGoalsLevel
              key={key}
              progressBarLevel={progressBarLevel}
            />
          );
        })}
      </Box>
    </Box>
  );
});
