import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {LoyaltyLevel} from '@youtoken/ui.resource-loyalty';
import {type BoxProps} from '@youtoken/ui.primitives';
import {AccountLevelProgressBarVariant} from '../../../types';
import {AccountLevelProgressBarDetailedPerksInfoItem} from './Item';

interface AccountLevelProgressBarDetailedTradeInfoItemSparksProps {
  variant?: AccountLevelProgressBarVariant;
  level: LoyaltyLevel;
}

export const AccountLevelProgressBarDetailedPerksInfoItemSparks: React.FC<
  AccountLevelProgressBarDetailedTradeInfoItemSparksProps & BoxProps
> = observer(({variant, level, ...boxProps}) => {
  const {t} = useTranslation();

  return (
    <AccountLevelProgressBarDetailedPerksInfoItem
      icon="spark"
      title={t('surface.loyalty.common.sparks')}
      description={t('surface.loyalty.common.progress_points_description', {
        amount:
          variant === AccountLevelProgressBarVariant.HODL
            ? level.hodlSparksRequiredVolume
            : level.conversion.sparksRequiredVolumeFormatted,
      })}
      value={
        variant === AccountLevelProgressBarVariant.HODL
          ? level.hodlSparksAmount
          : level.conversion.sparksAmount
      }
      colors={{
        primary: '$attention-01',
        secondary: '$attention-02',
      }}
      {...boxProps}
    />
  );
});
