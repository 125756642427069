import * as React from 'react';
import {observer} from 'mobx-react';
import {getResourceDescriptor, useResources} from '@youtoken/ui.data-storage';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {LoyaltyResource} from '@youtoken/ui.resource-loyalty';
import {UpgradeToLevel} from '../../components';

export interface UpgradeToLevelSmartProps {
  levelNumber: number;
  onPressAction?: () => void;
}

export const UpgradeToLevelSmart: React.FC<UpgradeToLevelSmartProps> = observer(
  ({levelNumber, onPressAction}) => {
    const {
      authMe: {
        products: {
          saving4: {isEnabled: showSavingsInfo},
          miner: {isEnabled: showMinerInfo},
        },
      },
      loyalty: {getLevel},
    } = useResources({
      authMe: getResourceDescriptor(AuthMeResource, {}),
      loyalty: getResourceDescriptor(LoyaltyResource, {}),
    });

    const level = getLevel(levelNumber);

    return (
      <UpgradeToLevel
        level={level}
        showSavingsInfo={showSavingsInfo}
        showMinerInfo={showMinerInfo}
        onPressAction={onPressAction}
      />
    );
  }
);
