import * as React from 'react';
import {ReanimatedBox, Text, Theme} from '@youtoken/ui.primitives';
import {IProgressBarProps} from '../types';
import Animated, {
  useAnimatedStyle,
  useDerivedValue,
  useSharedValue,
  withSpring,
  interpolate,
  Extrapolate,
} from 'react-native-reanimated';

export const ProgressBarText: React.FC<
  IProgressBarProps & {
    _progress: Animated.SharedValue<number>;
  }
> = ({text, color, progress, _progress}) => {
  const _color =
    progress >= 0.5
      ? '$text-04'
      : (
          {
            interactive: '$interactive-01',
            attention: '$attention-01',
            success: '$success-01',
            danger: '$danger-01',
          } as {[key in IProgressBarProps['color']]: keyof Theme['colors']}
        )[color];

  const style = useAnimatedStyle(() => {
    const normalizedProgress = Math.max(Math.min(_progress.value, 1), 0);

    if (_progress.value >= 0.5) {
      return {
        right: withSpring(`${100 - normalizedProgress * 100}%`, {
          overshootClamping: true,
        }),
      };
    }
    return {
      left: withSpring(`${normalizedProgress * 100}%`, {
        overshootClamping: true,
      }),
    };
  });

  return (
    <ReanimatedBox
      justifyContent="center"
      alignItems="flex-end"
      height={24}
      position="absolute"
      px={8}
      style={style}
    >
      <Text color={_color} variant="$body-02-medium-accent">
        {text}
      </Text>
    </ReanimatedBox>
  );
};
