import * as React from 'react';
import NiceModal, {useModal} from '@ebay/nice-modal-react';
import ReactModal from 'react-modal';
import {Box, Heading, TouchableBox} from '@youtoken/ui.primitives';
import {Icon} from '@youtoken/ui.icons';
import {TwoFactorAuthParams} from '../../types';
import {TwoFactorAuthForm} from '../TwoFactorAuthForm';
import {useTranslation} from '@youtoken/ui.service-i18n';

const customStyles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 500,
  },
  content: {
    position: 'relative',
    background: 'transparent',
    overflow: 'auto',
    WebkitOverflowScrolling: 'touch',
    borderRadius: 0,
    outline: 'none',
    width: '100%',
    border: 'none',
    inset: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

export const TwoFactorAuthVerificationModal = NiceModal.create<{
  params: TwoFactorAuthParams;
  onError: (error: any) => void;
  onSuccess: () => void;
}>(({onError, onSuccess, params}) => {
  const {hide, visible} = useModal();

  const handleManualClose = React.useCallback(() => {
    onError(new Error('Two factor auth was closed without a result'));
    hide();
  }, [hide, onError]);

  const handleSuccess = React.useCallback(() => {
    onSuccess();
    hide();
  }, [hide, onSuccess]);

  const handleError = React.useCallback(
    (error: any) => {
      onError(error);
    },
    [hide, onError]
  );

  const {t} = useTranslation();

  return (
    <ReactModal
      isOpen={visible}
      onRequestClose={handleManualClose}
      style={customStyles}
      testID="TWO_FACTOR_AUTH_MODAL"
    >
      <Box
        backgroundColor="$ui-background"
        borderRadius={6}
        width={{default: '100%', tablet: 590}}
      >
        <Box
          justifyContent="center"
          alignItems="center"
          borderBottomColor="$ui-01"
          borderBottomWidth={1}
          height={64}
          py={{default: 16, phone: 24}}
        >
          <Heading variant="$heading-01">{t('surface.2fa.title')}</Heading>
          <TouchableBox
            position="absolute"
            right={0}
            top={0}
            width={64}
            height={64}
            justifyContent="center"
            alignItems="center"
            onPress={handleManualClose}
            activeOpacity={0.8}
            testID="TWO_FACTOR_AUTH_MODAL_CLOSE"
          >
            <Icon name="close" color="$text-02" />
          </TouchableBox>
        </Box>

        <TwoFactorAuthForm
          autoFocus
          onError={handleError}
          onSuccess={handleSuccess}
          phoneMask={params.phoneMask}
          operationId={params.operationId}
          twoFactorAuthType={params.twoFactorAuthType}
        />
      </Box>
    </ReactModal>
  );
});

NiceModal.register('two-factor-auth-modal', TwoFactorAuthVerificationModal);
