import * as React from 'react';
import {observer} from 'mobx-react';
import {DATA_LAYER} from '@youtoken/ui.service-data-layer';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {Box, TouchableBox, Text} from '@youtoken/ui.primitives';
import {Badge} from '@youtoken/ui.elements';
import {Icon} from '@youtoken/ui.icons';
import {ReferralsRoundedText} from '..';

interface ReferralsAndBonusesSummaryProps {
  title: string;
  amount?: number;
  maxAmount?: number;
  newsCount?: number;
}

export const ReferralsAndBonusesSummary: React.FC<ReferralsAndBonusesSummaryProps> =
  observer(({title, amount, maxAmount, newsCount}) => {
    const {t} = useTranslation();

    const handlePress = React.useCallback(() => {
      DATA_LAYER.trackStrict('reward-widget-click', {});
      SHARED_ROUTER_SERVICE.navigate('RewardsMain');
    }, []);

    const badgeText = React.useMemo(() => {
      if (amount > 0) {
        return '$' + amount;
      }

      if (maxAmount) {
        return t('surface.referral_program.referrals_widget.get_amount', {
          amount: '$' + maxAmount,
        });
      }

      return t('surface.referral_program.referrals_widget.learn_and_earn');
    }, [t, amount, maxAmount]);

    return (
      <TouchableBox
        onPress={handlePress}
        justifyContent="center"
        alignItems="flex-end"
        flex={1}
      >
        <Box flexDirection="row" alignItems="center" mb={8}>
          <Text variant="$body-02" color="$text-02">
            {title}
          </Text>
          {newsCount > 0 && (
            <ReferralsRoundedText bg="$danger-01" textColor="$text-04" ml={4}>
              +{newsCount}
            </ReferralsRoundedText>
          )}
        </Box>
        <Badge
          variant="success"
          type="secondary"
          size="small"
          borderRadius={12}
        >
          <Box flexDirection="row" alignItems="center">
            <Icon name="gift" size={16} color="$success-01" />
            <Text variant="$body-02-medium-accent" color="$success-01" ml={4}>
              {badgeText}
            </Text>
          </Box>
        </Badge>
      </TouchableBox>
    );
  });
