import {computed, comparer, observable} from 'mobx';
import {computedFn} from 'mobx-utils';
import {Big} from 'big.js';
import {
  serializable,
  optional,
  alias,
  primitive,
  object,
  createSimpleSchema,
} from 'serializr';
import {bigNumber, number} from '@youtoken/ui.utils-serializr';
import {formatByTicker, formatPercent} from '@youtoken/ui.formatting-utils';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {RatesResource} from '@youtoken/ui.resource-rates';

export enum SavingsOverviewStatus {
  NEW = 'NEW',
  WAITING = 'WAITING',
  OPEN = 'OPEN',
  READY = 'READY',
  CLAIMED = 'CLAIMED',
}

export class SavingsOverviewResponseSaving {
  @serializable(bigNumber())
  apr!: Big;

  @serializable(alias('earn', bigNumber()))
  earnedInBaseTicker!: Big;

  @computed
  get aprFormatted() {
    return formatPercent(this.apr) + '%';
  }
}

export class SavingsOverviewResponse {
  @serializable(primitive())
  @observable
  status!: SavingsOverviewStatus;

  @serializable(optional(number()))
  level?: number;

  @serializable(optional(number()))
  timeLeft?: number;

  @serializable(optional(alias('amountTicker', primitive())))
  baseTicker?: string;

  @serializable(optional(alias('amount', bigNumber())))
  amountInBaseTicker?: Big;

  @serializable(optional(alias('limit', bigNumber())))
  limitInBaseTicker?: Big;

  @serializable(
    optional(
      object(
        createSimpleSchema({
          '*': object(SavingsOverviewResponseSaving),
        })
      )
    )
  )
  tickers?: Record<string, SavingsOverviewResponseSaving> = {};

  @computed
  get tickersKeys() {
    return this.tickers ? Object.keys(this.tickers) : [];
  }

  @computed
  get quoteTicker() {
    const {mainCurrency} = AuthMeResource.__DANGEROUSLY__getInstanceStatically(
      {}
    );

    return mainCurrency;
  }

  @computed
  get rate() {
    const {getRate} = RatesResource.__DANGEROUSLY__getInstanceStatically({});

    return getRate(this.baseTicker, this.quoteTicker);
  }

  @computed
  get earnedInQuoteTicker() {
    return this.tickersKeys.reduce((acc, baseTicker) => {
      const {earnedInQuoteTicker} = this.getEarnedByTicker(baseTicker);

      acc = acc.plus(earnedInQuoteTicker);

      return acc;
    }, new Big(0));
  }

  @computed
  get earnedInQuoteTickerFormatted() {
    return formatByTicker(this.earnedInQuoteTicker, this.quoteTicker);
  }

  @computed
  get progressBarAmountInQuoteTicker() {
    return this.amountInBaseTicker.mul(this.rate).toNumber();
  }

  @computed
  get progressBarLimitInQuoteTicker() {
    return this.limitInBaseTicker.mul(this.rate).toNumber();
  }

  getEarnedByTicker = computedFn(
    ticker => {
      const {getRate} = RatesResource.__DANGEROUSLY__getInstanceStatically({});

      const rate = getRate(ticker, this.quoteTicker);

      const earnedInBaseTicker = this.tickers[ticker].earnedInBaseTicker;
      const earnedInQuoteTicker = earnedInBaseTicker.mul(rate);

      return {
        earnedInBaseTicker,
        earnedInQuoteTicker,
        earnedInBaseTickerFormatted: formatByTicker(earnedInBaseTicker, ticker),
        earnedInQuoteTickerFormatted: formatByTicker(
          earnedInQuoteTicker,
          this.quoteTicker
        ),
      };
    },
    {
      equals: comparer.shallow,
    }
  );
}
