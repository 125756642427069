import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {LoyaltyResource} from '@youtoken/ui.resource-loyalty';
import {type BoxProps, Box, Text} from '@youtoken/ui.primitives';
import {Icon} from '@youtoken/ui.icons';
import {
  AccountLevelInfoItemCloudMinerFull,
  AccountLevelMinerWelcomePack,
} from '../../../components';

export interface LevelInfoMinerProps extends BoxProps {
  level: number;
}

export const LevelInfoMiner: React.FC<LevelInfoMinerProps> = observer(
  ({level, ...boxProps}) => {
    const {t} = useTranslation();

    const {getLevel} = LoyaltyResource.use({});

    const levelClass = getLevel(level);

    const {
      miner: {welcomePack, freeSparksPeriod},
    } = levelClass;

    return (
      <Box testID="LEVEL_INFO_MINER" {...boxProps}>
        <AccountLevelInfoItemCloudMinerFull
          level={levelClass}
          showBadgeInfo={false}
          p={0}
          mb={24}
        />
        <Box
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          py={4}
        >
          <Text variant="$body-02">
            {t('surface.savings.all_benefits.miner.welcome-pack')}
          </Text>
          <AccountLevelMinerWelcomePack count={welcomePack} showLabel={false} />
        </Box>
        {freeSparksPeriod && (
          <Box
            testID="ACCOUNT_LEVEL_MINER_FREE_SPARKS_PERIOD"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            py={4}
            mt={8}
          >
            <Text variant="$body-02">
              {t('surface.miner.loyalty.free_every')}
            </Text>
            <Box flexDirection="row" alignItems="center">
              <Icon name="spark" size={16} />
              <Text
                testID="ACCOUNT_LEVEL_MINER_FREE_SPARKS_PERIOD_VALUE"
                variant="$body-02"
              >
                {freeSparksPeriod}
              </Text>
            </Box>
          </Box>
        )}
      </Box>
    );
  }
);
