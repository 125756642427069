import * as React from 'react';
import {observer} from 'mobx-react';
import {currencyName} from '@youtoken/ui.resource-auth-me';
import {Box, BoxProps} from '@youtoken/ui.primitives';
import {MainCurrencyFeature} from './MainCurrencyFeature';
import {DropdownComponent} from '../Dropdown';
import {DATA_LAYER} from '@youtoken/ui.service-data-layer';

export const MainCurrencySelector: React.FC<BoxProps> = observer(
  ({...boxProps}) => {
    const feature = MainCurrencyFeature.use({});
    const {mainCurrency, setMainCurrency} = feature;

    // todo get list from backend
    // we have type currencyName = 'usd' | 'rub' | 'eur' | 'chf' | 'gbp';
    const currencyItems = [
      {text: 'USD', value: 'usd'},
      {text: 'EUR', value: 'eur'},
    ];

    const handleChange = React.useCallback(
      (ticker: string) => {
        DATA_LAYER.trackStrict('wallet-set-main-currency-attempt', {ticker});

        setMainCurrency(ticker as currencyName);
      },
      [setMainCurrency]
    );

    return (
      <Box flexDirection="row" {...boxProps}>
        <DropdownComponent
          initialItem={{text: mainCurrency.toUpperCase(), value: mainCurrency}}
          items={currencyItems}
          onChange={handleChange}
          testID="DROP_DOWN_CURRENCY_SELECTOR"
        />
      </Box>
    );
  }
);
