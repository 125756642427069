import * as React from 'react';
import {type PasscodeModalProps, PasscodeModal} from './PasscodeModal';

type PasscodeRequestParams = {
  promptMessage?: string;
};

export const PasscodeContext = React.createContext<PasscodeModalProps>({
  requestPasscode: () =>
    Promise.reject(new Error('PasscodeContext not initialized')),
});

export const PasscodeContextProvider: React.FC = ({children}) => {
  const PasscodeRef = React.useRef<PasscodeModalProps>(null);

  const value = React.useMemo(() => {
    return {
      requestPasscode: (params: PasscodeRequestParams) => {
        return PasscodeRef.current?.requestPasscode(params);
      },
    };
  }, []);

  return (
    <PasscodeContext.Provider value={value}>
      <PasscodeModal ref={PasscodeRef} />
      {children}
    </PasscodeContext.Provider>
  );
};

export const usePasscode = () => {
  const context = React.useContext(PasscodeContext);

  if (!context) {
    throw new Error(
      'usePasscode must be used within a PasscodeContextProvider'
    );
  }

  return context;
};
