import {computed} from 'mobx';
import {date, list, object, primitive, serializable} from 'serializr';
import {
  mainCurrencyVariants,
  MainCurrencyVariants,
} from '@youtoken/ui.utils-serializr';
import {formatByTicker} from '@youtoken/ui.formatting-utils';
import {
  isToday,
  isThisYear,
  format,
  formatDistanceToNow,
} from '@youtoken/ui.date-fns';
import {i18n} from '@youtoken/ui.service-i18n';
import {RewardType} from '../RewardType';

export class RewardsHistoryItemV1 {
  @serializable(primitive())
  id: string;

  @serializable(mainCurrencyVariants())
  earned: MainCurrencyVariants;

  @computed get title() {
    switch (this.rewardType) {
      case 'deposit':
        return i18n.t('surface.referral_program.earned_bonus.type_deposit');
      case 'convert':
        return i18n.t('surface.referral_program.earned_bonus.type_exchange');
      case 'hodl':
        return i18n.t('surface.referral_program.earned_bonus.type_hodl');
      case 'loan':
        return i18n.t('surface.referral_program.earned_bonus.type_loan');
      case 'dual':
        return i18n.t('surface.referral_program.earned_bonus.type_dual');
      default:
        return this.rewardType;
    }
  }

  @serializable(primitive())
  rewardType: RewardType;

  @serializable(primitive())
  earnType: 'own' | 'referral';

  @serializable(date())
  createdAt: Date;

  @computed get createdAtDistance() {
    return formatDistanceToNow(this.createdAt, {addSuffix: true});
  }

  @serializable(primitive())
  viewed: boolean;

  @computed get isToday() {
    return isToday(this.createdAt);
  }

  @computed get amountUSDFormatted() {
    return '+$' + formatByTicker(this.earned.usd, 'usd');
  }

  @computed get createdAtFormatted() {
    if (this.isToday) {
      return formatDistanceToNow(this.createdAt, {addSuffix: true});
    }

    return format(
      this.createdAt,
      isThisYear(this.createdAt) ? 'd MMMM, HH:mm' : 'd MMMM yyyy, HH:mm'
    );
  }
}

export class RewardsHistoryResponse {
  @serializable(primitive())
  count: number;

  @serializable(list(object(RewardsHistoryItemV1)))
  items: RewardsHistoryItemV1[];
}
