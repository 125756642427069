import * as React from 'react';
import {observer} from 'mobx-react';
import {type LoyaltyProgressBarLevel} from '@youtoken/ui.resource-loyalty';
import {Box, Text} from '@youtoken/ui.primitives';
import {MarkIconWithAchieved} from '../../../components/AccountLevelProgressBar/MarkIcon';

export type AccountLevelProgressBarDetailedNextGoalsProps = {
  progressBarLevel: LoyaltyProgressBarLevel;
};

export const AccountLevelProgressBarDetailedNextGoalsLevel: React.FC<AccountLevelProgressBarDetailedNextGoalsProps> =
  observer(({progressBarLevel}) => {
    const {
      isNext,
      isAchieved,
      volumeFormattedWithSeparators,
      level: {name, requiredVolume, requiredVolumeFormattedWithSeparators},
    } = progressBarLevel;

    return (
      <Box
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box flexDirection="row" alignItems="center">
          <MarkIconWithAchieved progressBarLevel={progressBarLevel} />
          <Text
            variant="$body-01"
            color={isAchieved ? '$text-02' : '$text-01'}
            ml={8}
          >
            {name}
          </Text>
        </Box>
        <Box flexDirection="row" alignItems="center">
          <Text variant="$body-01" color={isAchieved ? '$text-02' : '$text-01'}>
            {Boolean(requiredVolume) && (
              <>
                <Text color={isNext ? '$text-05' : undefined}>
                  {volumeFormattedWithSeparators}
                </Text>{' '}
                / {requiredVolumeFormattedWithSeparators}
              </>
            )}
          </Text>
        </Box>
      </Box>
    );
  });
