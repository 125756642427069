import * as React from 'react';
import {Box} from '@youtoken/ui.primitives';
import {PasscodeButton, PASSCODE_BUTTON_SIZE} from './PasscodeButton';

const BUTTON_GAP = 24;

type PasscodeKeyboardProps = {
  isLocalAuthAvailable?: boolean;
  isBackspaceActive?: boolean;
  localAuthType?: 'NONE' | 'FINGERPRINT' | 'FACIAL_RECOGNITION';
  disabled?: boolean;
  onNumberPress: (number: number) => void;
  onBackspacePress?: () => void;
  onLocalAuthPress?: () => void;
};

export const PasscodeKeyboard: React.FC<PasscodeKeyboardProps> = ({
  onNumberPress,
  onBackspacePress,
  onLocalAuthPress,
  isBackspaceActive = false,
  localAuthType = 'NONE',
  disabled = false,
}) => {
  const keyboardWidth = React.useMemo(() => {
    return PASSCODE_BUTTON_SIZE * 3 + BUTTON_GAP * 2;
  }, []);

  return (
    <Box width={keyboardWidth}>
      <Box
        flexDirection="row"
        width={keyboardWidth}
        mb={BUTTON_GAP}
        justifyContent="space-between"
      >
        <PasscodeButton
          number={1}
          onPress={onNumberPress}
          disabled={disabled}
        />
        <PasscodeButton
          number={2}
          onPress={onNumberPress}
          disabled={disabled}
        />
        <PasscodeButton
          number={3}
          onPress={onNumberPress}
          disabled={disabled}
        />
      </Box>
      <Box
        flexDirection="row"
        width={keyboardWidth}
        mb={BUTTON_GAP}
        justifyContent="space-between"
      >
        <PasscodeButton
          number={4}
          onPress={onNumberPress}
          disabled={disabled}
        />
        <PasscodeButton
          number={5}
          onPress={onNumberPress}
          disabled={disabled}
        />
        <PasscodeButton
          number={6}
          onPress={onNumberPress}
          disabled={disabled}
        />
      </Box>
      <Box
        flexDirection="row"
        width={keyboardWidth}
        mb={BUTTON_GAP}
        justifyContent="space-between"
      >
        <PasscodeButton
          number={7}
          onPress={onNumberPress}
          disabled={disabled}
        />
        <PasscodeButton
          number={8}
          onPress={onNumberPress}
          disabled={disabled}
        />
        <PasscodeButton
          number={9}
          onPress={onNumberPress}
          disabled={disabled}
        />
      </Box>

      <Box
        flexDirection="row"
        width={keyboardWidth}
        mb={BUTTON_GAP}
        justifyContent="space-between"
      >
        {localAuthType !== 'NONE' ? (
          <PasscodeButton
            icon={localAuthType === 'FINGERPRINT' ? 'fingerprint' : 'face_id'}
            type="ghost"
            onPress={onLocalAuthPress}
            disabled={disabled}
          />
        ) : (
          <Box width={PASSCODE_BUTTON_SIZE} height={PASSCODE_BUTTON_SIZE} />
        )}
        <PasscodeButton number={0} onPress={onNumberPress} />
        <PasscodeButton
          icon="backspace"
          type="secondary"
          disabled={!isBackspaceActive || disabled}
          onPress={onBackspacePress}
        />
      </Box>
    </Box>
  );
};
