import * as React from 'react';
import {observer} from 'mobx-react';
import {Trans, useTranslation} from '@youtoken/ui.service-i18n';
import {Box, Text} from '@youtoken/ui.primitives';
import {Timer} from '@youtoken/ui.elements';
import {QuestionTooltip} from '@youtoken/ui.tooltip';
import {ProgressLevelLeftTimeLevel} from './ProgressLevelLeftTimeLevel';
import {
  getLevelName,
  getLevelIconName,
  getLevelColor,
} from '@youtoken/ui.loyalty-miner-utils';
import {prepareChildren} from '../utils';

type ProgressLevelLeftTimeProps = {
  currentLevel: number;
  nextSprintLevel: number;
  timeLeft: number;
  refetch: () => void;
};

export const ProgressLevelLeftTime: React.FC<ProgressLevelLeftTimeProps> =
  observer(({currentLevel, nextSprintLevel, timeLeft, refetch}) => {
    const {t} = useTranslation();

    const nextSprintLevelName = getLevelName(nextSprintLevel);
    const nextSprintLevelIconName = getLevelIconName(nextSprintLevel);
    const nextSprintLevelIconColor = getLevelColor(nextSprintLevel);

    const i18nKey =
      nextSprintLevel === currentLevel
        ? 'surface.loyalty.keep_staing_in'
        : 'surface.loyalty.downgrade_in';

    // needed to avoid frequent refetching while backend returns timeLeftUntilLevelDowngrade < 0
    // see https://github.com/YouToken/ui-monorepo/pull/1502#discussion_r1108734631
    // will be fixed in https://youhodler.atlassian.net/browse/EN-270
    const handleRefetch = React.useCallback(() => {
      setTimeout(refetch, 1000);
    }, [refetch]);

    return (
      <Box
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box flex={1} mr={16}>
          <Trans
            t={t}
            parent={({children}) => {
              return (
                <Box flexDirection="row" alignItems="baseline" flexWrap="wrap">
                  {prepareChildren(children)}
                </Box>
              );
            }}
            components={{
              Level: (
                <ProgressLevelLeftTimeLevel
                  name={nextSprintLevelName}
                  iconName={nextSprintLevelIconName}
                  iconColor={nextSprintLevelIconColor}
                />
              ),
              LeftTime: (
                <Trans
                  t={t}
                  i18nKey="surface.loyalty.left_time"
                  parent={({children}) => {
                    return <Text variant="$body-02">{children}</Text>;
                  }}
                  components={{
                    Timer: (
                      <Timer
                        timeLeft={timeLeft}
                        variant="$body-02-medium-accent"
                        onTimerEnd={handleRefetch}
                      />
                    ),
                  }}
                />
              ),
            }}
            i18nKey={`${i18nKey}_new`}
          />
        </Box>
        <Box flexShrink={0}>
          <QuestionTooltip content={t(`${i18nKey}_hint`)} />
        </Box>
      </Box>
    );
  });
