import * as React from 'react';
import {
  Text,
  Box,
  TouchableBox,
  TouchableBoxProps,
} from '@youtoken/ui.primitives';
import {Icon} from '@youtoken/ui.icons';

type PromoItemVariant =
  | 'blue'
  | 'blueTransparent'
  | 'green'
  | 'greenTransparent'
  | 'red'
  | 'redTransparent'
  | 'yellow'
  | 'yellowTransparent'
  | undefined;

export interface PromoItemProps extends Omit<TouchableBoxProps, 'onPress'> {
  onPress: () => void;
  text: string;
  iconName: React.ComponentProps<typeof Icon>['name'];
  variant: PromoItemVariant;
  testID?: string;
}

const getIconColorByVariant = (variant: PromoItemVariant) => {
  switch (variant) {
    case 'blue':
    case 'green':
    case 'red':
    case 'yellow':
      return '$text-04';
    case 'greenTransparent':
      return '$success-01';
    case 'redTransparent':
      return '$danger-04';
    case 'yellowTransparent':
      return '$attention-04';
    case 'blueTransparent':
    default:
      return '$text-05';
  }
};

const getBgColorByVariant = (variant: PromoItemVariant) => {
  switch (variant) {
    case 'green':
      return '$success-01';
    case 'greenTransparent':
      return '$success-02';
    case 'red':
      return '$danger-01';
    case 'redTransparent':
      return '$danger-02';
    case 'yellow':
      return '$attention-01';
    case 'yellowTransparent':
      return '$attention-02';
    case 'blue':
      return '$interactive-01';
    case 'blueTransparent':
    default:
      return '$interactive-02';
  }
};

const getTextColorByVariant = (variant: PromoItemVariant) => {
  switch (variant) {
    case 'blue':
    case 'green':
    case 'red':
    case 'yellow':
      return '$text-04';
    case 'greenTransparent':
      return '$success-01';
    case 'redTransparent':
      return '$danger-04';
    case 'yellowTransparent':
      return '$attention-04';
    case 'blueTransparent':
    default:
      return '$text-05';
  }
};

export const PromoItem: React.FC<PromoItemProps> = React.memo(
  ({
    onPress,
    text,
    iconName,
    variant = 'blueTransparent',
    testID,
    ...otherProps
  }) => {
    const iconColor = getIconColorByVariant(variant);
    const bgColor = getBgColorByVariant(variant);
    const textColor = getTextColorByVariant(variant);

    return (
      <TouchableBox
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        minHeight={64}
        px={20}
        py={10}
        backgroundColor={bgColor}
        onPress={onPress}
        borderRadius={{desktop: 10, default: 0}}
        testID={testID}
        {...otherProps}
      >
        <Box flexShrink={0}>
          <Icon name={iconName} color={iconColor} />
        </Box>
        <Box mx={15} flexShrink={1} flexGrow={1}>
          {!!text && <Text color={textColor}>{text}</Text>}
        </Box>
        <Box flexShrink={0}>
          <Icon name="chevron_right" color={iconColor} />
        </Box>
      </TouchableBox>
    );
  }
);
