import * as React from 'react';
import {observer} from 'mobx-react';
import {
  RewardOverviewStatus,
  RewardsResource,
} from '@youtoken/ui.resource-rewards-v3';
import {type RewardsOverviewType} from '../types';
import {
  RewardsLevelsVariant,
  RewardsLevels,
  RewardsLevel,
} from '../../../components';

interface RewardsOverviewProgressSliderProps {
  type?: RewardsOverviewType;
}

export const RewardsOverviewProgressSlider: React.FC<RewardsOverviewProgressSliderProps> =
  observer(({type = 'challenge'}) => {
    const {
      data: {status, levels, initialLevelNumber},
    } = RewardsResource.use({});

    const [currentLevel, setCurrentLevel] = React.useState(initialLevelNumber);

    const variant =
      status === RewardOverviewStatus.OPEN
        ? RewardsLevelsVariant.ACTIVE
        : RewardsLevelsVariant.COMPLETED;

    const showActions =
      type === 'challenge' && variant === RewardsLevelsVariant.ACTIVE;

    const level = React.useMemo(() => {
      return levels.find(l => l.level === currentLevel);
    }, [levels, currentLevel]);

    return (
      <>
        <RewardsLevels
          variant={variant}
          levels={levels}
          currentLevel={currentLevel}
          onChangeCurrentLevel={setCurrentLevel}
        />
        <RewardsLevel level={level} showActions={showActions} />
      </>
    );
  });
