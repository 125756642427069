import * as React from 'react';
import {TwoFactorAuthVerificationComponent} from './TwoFactorAuthVerificationComponent';
import {TwoFactorAuthParams} from './types';

export class TwoFactorAuthVerification extends React.PureComponent {
  twoFactoAuthRef = React.createRef<{
    requestTwoFactorAuth: (params: TwoFactorAuthParams) => Promise<null>;
  }>();

  requestTwoFactorAuth = (params: TwoFactorAuthParams) => {
    return this.twoFactoAuthRef.current?.requestTwoFactorAuth(params);
  };

  render() {
    return <TwoFactorAuthVerificationComponent ref={this.twoFactoAuthRef} />;
  }
}
