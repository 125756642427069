import * as React from 'react';
import {observer} from 'mobx-react';
import {LoyaltyProgressBarLevel} from '@youtoken/ui.resource-loyalty';
import {type BoxProps, Box} from '@youtoken/ui.primitives';
import {ProgressBar} from '@youtoken/ui.progress-bar';

import {MarkIconWithAchieved} from '../MarkIcon';

export type AccountLevelProgressBarContentProps = {
  value: number;
  progressBarLevels: LoyaltyProgressBarLevel[];
};

export const AccountLevelProgressBarContent: React.FC<
  AccountLevelProgressBarContentProps & BoxProps
> = observer(
  ({
    value,
    progressBarLevels: [firstProgressBarLevel, lastProgressBarLevel],
    ...boxProps
  }) => {
    return (
      <Box
        testID="ACCOUNT_LEVEL_FORECAST_CONTENT"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        {...boxProps}
      >
        <MarkIconWithAchieved progressBarLevel={firstProgressBarLevel} />
        <Box flex={1} mx={7}>
          <ProgressBar
            size="tiny"
            color={firstProgressBarLevel.isAchieved ? 'success' : 'interactive'}
            progress={value}
          />
        </Box>
        <MarkIconWithAchieved progressBarLevel={lastProgressBarLevel} />
      </Box>
    );
  }
);
