import * as React from 'react';
import ReactModal from 'react-modal';
import {ScrollView} from 'react-native';
import {cell} from '@youtoken/ui.cell';
import {
  Box,
  type BoxProps,
  TouchableBox,
  useTheme,
} from '@youtoken/ui.primitives';
import {Icon} from '@youtoken/ui.icons';
import {CloseIcon} from './CloseIcon';
import {type ModalProps} from './types';
import {ModalHeader} from './ModalHeader';

// NOTE. The content must be a cell
const ModalContent: React.FC<BoxProps> = cell(({children}) => children, {
  CellWrap: props => {
    return <Box {...props} />;
  },
});

export const Modal: React.FC<ModalProps & BoxProps> = ({
  title,
  subtitle,
  isOpen,
  onClose,
  webMinHeight = 350,
  webWidth = 590,
  webHeight,
  shouldBeScrollable = false,
  shouldShowHeaderSeparator = true,
  closePosition = 'inside',
  closeIconColor = '$text-01',
  overflow = 'hidden',
  testID = 'MODAL',
  children,
  webOverlayBackgroundColor = '$blackout-01',
  closeable = true,
  ...boxProps
}) => {
  const {colors} = useTheme();

  const customStyles = React.useMemo(() => {
    return {
      overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: colors[webOverlayBackgroundColor],
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 400,
      },
      content: {
        position: 'unset',
        background: 'transparent',
        outline: 'none',
        padding: 0,
        border: 'none',
        display: 'flex',
        justifyContent: 'center',
        overflow,
      },
    };
  }, [colors, webOverlayBackgroundColor, overflow]);

  return (
    <ReactModal
      shouldCloseOnOverlayClick={closeable}
      isOpen={isOpen}
      onRequestClose={closeable ? onClose : undefined}
      style={customStyles}
    >
      {closeable && closePosition === 'outside' && (
        <TouchableBox
          alignItems="center"
          justifyContent="center"
          width={40}
          height={40}
          position="absolute"
          top={16}
          right={16}
          onPress={onClose}
        >
          <Box
            justifyContent="center"
            alignItems="center"
            width={24}
            height={24}
            backgroundColor="$text-04"
            borderRadius={12}
          >
            <Icon name="close" size={20} color="$text-02" />
          </Box>
        </TouchableBox>
      )}
      <ModalContent
        backgroundColor="$ui-background"
        borderRadius={10}
        width={{default: `calc(100vw - 24px)`, desktop: webWidth}}
        height={webHeight}
        minHeight={webMinHeight}
        maxHeight="90vh"
        overflow={overflow}
        testID={testID}
        {...boxProps}
      >
        <ModalHeader
          title={title}
          subtitle={subtitle}
          withSeparator={shouldShowHeaderSeparator}
          testID={testID}
        />
        {closeable && closePosition === 'inside' && (
          <CloseIcon
            onClose={onClose}
            closeIconColor={closeIconColor}
            testID={`${testID}_CLOSE`}
          />
        )}
        {shouldBeScrollable ? (
          <ScrollView style={{height: '100%'}}>{children}</ScrollView>
        ) : (
          <Box flex={1}>{children}</Box>
        )}
      </ModalContent>
    </ReactModal>
  );
};
