import * as React from 'react';
import {LoyaltyResource} from '@youtoken/ui.resource-loyalty';
import {type BoxProps, Box} from '@youtoken/ui.primitives';
import {LevelStatusView} from '@youtoken/ui.loyalty-miner-components';
import {AccountLevelCurrent} from '../../../AccountLevelCurrent';

export interface AccountLevelAllBenefitsLevelStatusViewProps {
  level: number;
}

export const AccountLevelAllBenefitsLevelStatusView: React.FC<
  AccountLevelAllBenefitsLevelStatusViewProps & BoxProps
> = React.memo(({level, ...boxProps}) => {
  const {
    data: {currentLevel},
    getLevel,
  } = LoyaltyResource.use({});

  const {status} = getLevel(level);

  if (currentLevel === level) {
    return (
      <Box
        justifyContent="center"
        alignItems="center"
        position="absolute"
        top={16}
        right={16}
        {...boxProps}
      >
        <AccountLevelCurrent />
      </Box>
    );
  }

  return <LevelStatusView status={status} {...boxProps} />;
});
