import * as React from 'react';
import {invariant} from '@youtoken/ui.utils';
import {LoyaltyLevel} from '@youtoken/ui.resource-loyalty';
import {AccountLevelInfoItemAllBenefits} from '../../smarts';
import {
  AccountLevelInfoItemCloudMinerShort,
  AccountLevelInfoItemCloudMinerFull,
} from '../AccountLevelInfoItemCloudMiner';
import {
  AccountLevelInfoItemConversionShort,
  AccountLevelInfoItemConversionFull,
} from '../AccountLevelInfoItemConversion';
import {
  AccountLevelInfoItemSavingsShort,
  AccountLevelInfoItemSavingsFull,
} from '../AccountLevelInfoItemSavings';

type AccountLevelInfoItemVariant = 'full' | 'short';

type AccountLevelInfoItemName = 'miner' | 'exchange' | 'savings';

const AccountLevelInfoItems = {
  short: {
    miner: AccountLevelInfoItemCloudMinerShort,
    exchange: AccountLevelInfoItemConversionShort,
    savings: AccountLevelInfoItemSavingsShort,
  },
  full: {
    miner: AccountLevelInfoItemCloudMinerFull,
    exchange: AccountLevelInfoItemConversionFull,
    savings: AccountLevelInfoItemSavingsFull,
  },
} as const;

const getItem = <
  Variant extends AccountLevelInfoItemVariant = AccountLevelInfoItemVariant,
  Name extends AccountLevelInfoItemName = AccountLevelInfoItemName
>(
  variant: Variant,
  name: Name
) => {
  const Item = AccountLevelInfoItems[variant][name];

  invariant(Item, `Item doesn't exist for [${variant}, ${name}]`);

  return Item;
};

export const useItems = ({
  showMiner,
  showExchange,
  showSavings,
}: {
  showMiner: boolean;
  showExchange: boolean;
  showSavings: boolean;
}) => {
  return React.useMemo(() => {
    const showBenefits = showMiner || showExchange || showSavings;

    return [
      showMiner && 'miner',
      showExchange && 'exchange',
      showSavings && 'savings',
      showBenefits && 'benefits',
    ].filter(Boolean);
  }, [showMiner, showExchange, showSavings]);
};

export const useRenderItems = (
  level: LoyaltyLevel,
  variant: AccountLevelInfoItemVariant,
  config: {
    cardColor?: LoyaltyLevel['cardColor'];
    disabledSavings?: boolean;
  } = {}
) => {
  const AccountLevelInfoItemCloudMiner = getItem(variant, 'miner');
  const AccountLevelInfoItemConversion = getItem(variant, 'exchange');
  const AccountLevelInfoItemSavings = getItem(variant, 'savings');

  return React.useCallback(
    (item, index) => {
      const borderTopWidth = Number(index > 0);

      if (item === 'miner') {
        return (
          <AccountLevelInfoItemCloudMiner
            key={item}
            level={level}
            borderColor={config.cardColor}
            borderTopWidth={borderTopWidth}
          />
        );
      }

      if (item === 'exchange') {
        return (
          <AccountLevelInfoItemConversion
            key={item}
            level={level}
            borderColor={config.cardColor}
            borderTopWidth={borderTopWidth}
          />
        );
      }

      if (item === 'savings') {
        return (
          <AccountLevelInfoItemSavings
            key={item}
            level={level}
            borderColor={config.cardColor}
            borderTopWidth={borderTopWidth}
            disabled={config.disabledSavings}
          />
        );
      }

      if (item === 'benefits') {
        return (
          <AccountLevelInfoItemAllBenefits
            key={item}
            level={level.level}
            borderColor={config.cardColor}
            borderTopWidth={borderTopWidth}
          />
        );
      }

      return null;
    },
    [level, variant, config]
  );
};
