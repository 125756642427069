import {computed, action} from 'mobx';
import {createFeature, getResourceDescriptor} from '@youtoken/ui.data-storage';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {LoyaltyResource} from '@youtoken/ui.resource-loyalty';
import {MinerOverviewResource} from '.';

export class MinerFeature extends createFeature({
  getKey: () => {
    return `miner`;
  },
  getResources: () => {
    return {
      minerOverview: getResourceDescriptor(MinerOverviewResource, {}),
      loyalty: getResourceDescriptor(LoyaltyResource, {}),
      authMe: getResourceDescriptor(AuthMeResource, {}),
    };
  },
}) {
  @computed
  get isHowItWorksBannerEnabled() {
    return this.resources.authMe.data.clientSettings
      .enableMinerHowItWorksBanner;
  }

  @action setHowItWorksBannerEnabled = state => {
    this.resources.authMe.setMinerHowItWorksBannerEnabled(state);
  };

  @action refetchMinerAndLoyalty = () => {
    this.resources.minerOverview.refetch();
    this.resources.loyalty.refetch();
  };
}
