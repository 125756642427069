import * as React from 'react';
import {observer} from 'mobx-react';
import {LoyaltyLevel} from '@youtoken/ui.resource-loyalty';
import {type BoxProps, Box} from '@youtoken/ui.primitives';
import {AccountLevelProgressBarVariant} from '../../../types';
import {AccountLevelProgressBarDetailedPerksInfoItemProgressPoints} from './ItemProgressPoints';
import {AccountLevelProgressBarDetailedPerksInfoItemSparks} from './ItemSparks';

export interface AccountLevelProgressBarDetailedTradePerksInfoProps {
  variant?: AccountLevelProgressBarVariant;
  level: LoyaltyLevel;
}

export const AccountLevelProgressBarDetailedTradePerksInfo: React.FC<
  AccountLevelProgressBarDetailedTradePerksInfoProps & BoxProps
> = observer(({variant, level, ...boxProps}) => {
  return (
    <Box flexDirection="row" gap={12} {...boxProps}>
      <AccountLevelProgressBarDetailedPerksInfoItemProgressPoints
        variant={variant}
        level={level}
      />
      <AccountLevelProgressBarDetailedPerksInfoItemSparks
        variant={variant}
        level={level}
      />
    </Box>
  );
});
