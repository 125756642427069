import * as React from 'react';
import {
  type ConfirmationModalProps,
  ConfirmationModal,
} from './ConfirmationModal';

export const ConfirmationContext = React.createContext<ConfirmationModalProps>({
  requestConfirmation: () =>
    Promise.reject(new Error('ConfirmationContext not initialized')),
});

export const ConfirmationContextProvider: React.FC = ({children}) => {
  const confirmationRef = React.useRef<ConfirmationModalProps>(null);

  const value = React.useMemo(() => {
    return {
      requestConfirmation: params => {
        return confirmationRef.current?.requestConfirmation(params);
      },
    };
  }, []);

  return (
    <ConfirmationContext.Provider value={value}>
      <ConfirmationModal ref={confirmationRef} />
      {children}
    </ConfirmationContext.Provider>
  );
};

export const useConfirmation = () => {
  const context = React.useContext(ConfirmationContext);

  if (!context) {
    throw new Error(
      'useConfirmation must be used within a ConfirmationContextProvider'
    );
  }

  return context;
};
