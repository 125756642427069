import * as React from 'react';
import {observer} from 'mobx-react';
import {i18n} from '@youtoken/ui.service-i18n';
import {LoyaltyResource} from '@youtoken/ui.resource-loyalty';
import {type BoxProps, Box} from '@youtoken/ui.primitives';
import {AccountLevelProgressBarVariant} from '../types';
import {ScrollView} from '../../components';
import {AccountLevelsSlider} from '../../smarts';
import {AccountLevelProgressBarDetailedNextGoals} from './NextGoals';
import {AccountLevelProgressBarDetailedPerks} from './Perks';
import {useSafeAreaInsets} from 'react-native-safe-area-context';

export const getAccountLevelProgressDetailedTitle = () => {
  return i18n.t('surface.loyalty.account_level');
};

export type AccountLevelProgressDetailedProps = {
  variant?: AccountLevelProgressBarVariant;
  volume: number;
  sparks: number;
};

export const AccountLevelProgressDetailed: React.FC<
  AccountLevelProgressDetailedProps & BoxProps
> = observer(({variant, volume, ...boxProps}) => {
  const {bottom} = useSafeAreaInsets();

  const {getProgressBarLevelsWidgetDetailed} = LoyaltyResource.use({});

  const progressBarLevels = getProgressBarLevelsWidgetDetailed(volume);

  const {level} = progressBarLevels[progressBarLevels.length - 1];

  return (
    <Box flex={1} {...boxProps}>
      <ScrollView
        contentContainerStyle={{
          paddingBottom: bottom,
        }}
        scrollIndicatorInsets={{
          bottom,
        }}
      >
        <AccountLevelProgressBarDetailedNextGoals
          progressBarLevels={progressBarLevels}
          p={{default: 16, phone: 24}}
        />
        <AccountLevelProgressBarDetailedPerks
          variant={variant}
          level={level}
          p={{default: 16, phone: 24}}
          borderTopWidth={1}
          borderColor="$ui-01"
        />
        <AccountLevelsSlider initialIndex={level.level - 1} />
      </ScrollView>
    </Box>
  );
});
