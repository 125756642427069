import * as React from 'react';
import {
  Box,
  type TColorTokens,
  Text,
  TouchableBox,
  TouchableBoxProps,
  useIsDesktop,
} from '@youtoken/ui.primitives';
import {Icon} from '@youtoken/ui.icons';
import {Illustration} from '@youtoken/ui.elements';
import {Button} from '@youtoken/ui.buttons';
import {useTranslation} from '@youtoken/ui.service-i18n';

const images = {
  money: 'illustration-7',
  multihodl: 'illustration-3',
  ibanSetup: 'illustration-11',
  ibanOpenAccount: 'illustration-15',
  loyalty: 'illustration-14',
} as const;

type ImageName = keyof typeof images;

type PromoBannerVariant = 'blue' | 'blueTransparent';

const getColorsByVariant = (
  variant: PromoBannerVariant
): {
  bgColor: keyof TColorTokens;
  textColor: keyof TColorTokens;
  closeIconColor: keyof TColorTokens;
  buttonType: 'primary' | 'secondary' | 'ghost' | 'inverted';
} => {
  switch (variant) {
    case 'blue':
      return {
        bgColor: '$interactive-01',
        textColor: '$text-04',
        closeIconColor: '$text-04',
        buttonType: 'inverted',
      };
    case 'blueTransparent':
    default:
      return {
        bgColor: '$interactive-02',
        textColor: '$text-05',
        closeIconColor: '$text-05',
        buttonType: 'primary',
      };
  }
};

export interface PromoBannerProps extends Omit<TouchableBoxProps, 'onPress'> {
  onPressBanner?: () => void;
  onPressClose?: () => void;
  showActionButton?: boolean;
  text?: string;
  imageName?: ImageName;
  variant?: PromoBannerVariant;
}

export const PromoBanner: React.FC<PromoBannerProps> = React.memo(
  ({
    onPressBanner,
    onPressClose,
    showActionButton = false,
    text,
    imageName = 'multihodl',
    variant = 'blueTransparent',
    children,
    ...otherProps
  }) => {
    const {t} = useTranslation();
    const isDesktop = useIsDesktop();
    const {bgColor, textColor, closeIconColor, buttonType} =
      getColorsByVariant(variant);

    return (
      <TouchableBox
        position="relative"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        minHeight={80}
        py={4}
        pl={16}
        pr={isDesktop ? 32 : 24} // space for close button
        backgroundColor={bgColor}
        onPress={onPressBanner}
        borderRadius={12}
        {...otherProps}
      >
        {images[imageName] && (
          <Box flexShrink={0} height={72} width={72}>
            <Illustration
              name={images[imageName]}
              style={{
                width: '100%',
                height: '100%',
              }}
            />
          </Box>
        )}
        <Box flexShrink={1} flexGrow={1} mx={8}>
          {children ?? (
            <Text color={textColor} variant="$body-02">
              {text}
            </Text>
          )}
        </Box>
        {showActionButton && (
          <Button
            onPress={onPressBanner}
            size={isDesktop ? 'medium' : 'small'}
            type={buttonType}
          >
            {/* @ts-ignore */}
            {t('common.actions.start').toUpperCase()}
          </Button>
        )}
        {Boolean(onPressClose) && (
          <TouchableBox
            position="absolute"
            top={4}
            right={4}
            width={24}
            height={24}
            alignItems="center"
            justifyContent="center"
            onPress={onPressClose}
            testID="PROMO_BANNER_CLOSE_BUTTON"
          >
            <Icon name="close" size={16} color={closeIconColor} />
          </TouchableBox>
        )}
      </TouchableBox>
    );
  }
);
