import {primitive, serializable} from 'serializr';
import {RewardType} from '../RewardType';
import {computed} from 'mobx';
import {formatByTicker} from '@youtoken/ui.formatting-utils';
import {BaseIconName} from '@youtoken/ui.icons';
import {i18n} from '@youtoken/ui.service-i18n';
import {
  MainCurrencyVariants,
  mainCurrencyVariants,
} from '@youtoken/ui.utils-serializr';

export class FriendsActivityItem {
  @serializable(primitive())
  type: RewardType;

  @serializable(primitive())
  users: number;

  @serializable(mainCurrencyVariants())
  earned: MainCurrencyVariants;

  @computed get revenueTotalUSD() {
    return '$' + formatByTicker(this.earned.usd, 'usd');
  }

  @computed get icon(): BaseIconName {
    switch (this.type) {
      case 'deposit':
        return 'fiat_deposit';
      case 'convert':
        return 'exchange';
      case 'hodl':
        return 'hodl';
      case 'loan':
        return 'get_loan';
      case 'dual':
        return 'dual';
      default:
        return 'exchange';
    }
  }

  @computed get title() {
    switch (this.type) {
      case 'deposit':
        return i18n.t(
          'surface.referral_program.referrals.friends_activity.type_deposit'
        );
      case 'convert':
        return i18n.t(
          'surface.referral_program.referrals.friends_activity.type_exchange'
        );
      case 'hodl':
        return i18n.t(
          'surface.referral_program.referrals.friends_activity.type_hodl'
        );
      case 'loan':
        return i18n.t(
          'surface.referral_program.referrals.friends_activity.type_loan'
        );
      case 'dual':
        return i18n.t(
          'surface.referral_program.referrals.friends_activity.type_dual'
        );

      default:
        return this.type;
    }
  }
}
