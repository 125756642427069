import {observable, computed} from 'mobx';
import {serializable, object, list, date} from 'serializr';
import {Big} from 'big.js';
import {bigNumber} from '@youtoken/ui.utils-serializr';
import {formatByTicker} from '@youtoken/ui.formatting-utils';
import {
  isToday,
  isThisYear,
  format,
  formatDistanceToNow,
} from '@youtoken/ui.date-fns';

export class RewardsHistoryItemV3 {
  @serializable(bigNumber())
  @observable
  amountUSD: Big;

  @serializable(date())
  @observable
  createdAt: Date;

  @computed get isToday() {
    return isToday(this.createdAt);
  }

  @computed get amountUSDFormatted() {
    return '+$' + formatByTicker(this.amountUSD, 'usd');
  }

  @computed get createdAtFormatted() {
    if (this.isToday) {
      return formatDistanceToNow(this.createdAt, {addSuffix: true});
    }

    return format(
      this.createdAt,
      isThisYear(this.createdAt) ? 'd MMMM, HH:mm' : 'd MMMM yyyy, HH:mm'
    );
  }
}

export class RewardsHistoryResponse {
  @serializable(list(object(RewardsHistoryItemV3)))
  @observable
  items: RewardsHistoryItemV3[];
}
