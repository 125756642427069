import React from 'react';
import {TextInput} from 'react-native';
import {Box, Text, useActiveThemeType} from '@youtoken/ui.primitives';
import {NumberItem} from './NumberItem';
import {useTranslation} from '@youtoken/ui.service-i18n';

export interface OTPInputProps {
  value?: string;
  onChange?: (value: string) => void;
  onSubmit?: (code: string) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  length?: number;
  lengthChunk?: number;
  isChecking?: boolean;
  autoFocus?: boolean;
  errorText?: string;
  testID?: string;
}

export const normalizeValue = (code: string, length: number) => {
  const matches = code.match(/\d+/);

  if (matches) {
    return matches[0].substr(0, length);
  }

  return '';
};

const length = 6;
const lengthChunk = 3;

const slots = Array.from(Array(length));

export const OTPInput = React.forwardRef<any, OTPInputProps>(
  (
    {
      value = '',
      onChange,
      onSubmit,
      onBlur,
      onFocus,
      isChecking,
      autoFocus,
      errorText,
      testID = 'OTP_INPUT',
    },
    ref
  ) => {
    const themeType = useActiveThemeType();
    const [focused, setFocused] = React.useState(true);

    const {t} = useTranslation();

    const hasError = Boolean(errorText);

    const handleFocus = React.useCallback(() => {
      setFocused(true);
      onFocus?.();
    }, [onFocus]);

    const handleBlur = React.useCallback(() => {
      setFocused(false);
      onBlur?.();
    }, [onBlur]);

    const handleChangeText = React.useCallback(
      value => {
        if (!isChecking) {
          onChange?.(normalizeValue(value, length));
        }
      },
      [onChange, isChecking]
    );

    React.useEffect(() => {
      if (value.length === length) {
        onSubmit?.(value);
      }
    }, [onSubmit, value]);

    return (
      <Box
        justifyContent="center"
        alignItems="center"
        opacity={isChecking ? 0.6 : 1}
        testID={testID}
      >
        <Box position="relative">
          <Box flexDirection="row">
            {slots.map((_value, index) => {
              return (
                <NumberItem
                  testID={`${testID}_NUMBER_ITEM_${index}`}
                  key={index}
                  filled={Boolean(value[index]) ? true : false}
                  focused={focused && value.length === index}
                  hasError={hasError}
                  mr={
                    index + 1 < length
                      ? (index + 1) % lengthChunk === 0
                        ? 16
                        : 8
                      : undefined
                  }
                >
                  {value[index]}
                </NumberItem>
              );
            })}
          </Box>
          <Box
            position="absolute"
            top={0}
            left={0}
            bottom={0}
            right={0}
            opacity={0}
          >
            <TextInput
              keyboardAppearance={themeType}
              style={{
                width: '100%',
                height: '100%',
              }}
              value={value}
              onChangeText={handleChangeText}
              onFocus={handleFocus}
              onBlur={handleBlur}
              autoFocus={autoFocus}
              autoCorrect={false}
              autoCapitalize="none"
              textContentType="oneTimeCode"
              keyboardType="number-pad"
              ref={ref}
              testID={`${testID}_HIDDEN_INPUT`}
            />
          </Box>
        </Box>

        <Box height={16} justifyContent="center" alignItems="center" mt={8}>
          {hasError && (
            <Text
              variant="$body-02"
              color="$danger-01"
              testID={`${testID}_ERROR`}
            >
              {errorText}
            </Text>
          )}
          {isChecking && (
            <Text
              variant="$body-02"
              color="$ui-02"
              testID={`${testID}_CHECKING`}
            >
              {t('surface.2fa.checking')}
            </Text>
          )}
        </Box>
      </Box>
    );
  }
);
