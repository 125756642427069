import * as React from 'react';
import {TouchableOpacity} from 'react-native';
import {Text, Box, useTheme} from '@youtoken/ui.primitives';
import {Icon} from '@youtoken/ui.icons';
import {useHover} from 'react-use-gesture';

export interface DropdownItemData {
  text: string;
  value: string;
}

interface DropdownItemProps extends DropdownItemData {
  onChoose: (value: DropdownItemData) => void;
  isActive?: boolean;
}
export const DropdownItem: React.FC<DropdownItemProps> = ({
  text,
  value,
  onChoose,
  isActive = false,
}) => {
  const handlePress = React.useCallback(() => {
    onChoose({text, value});
  }, [onChoose, value]);

  const [hover, setHover] = React.useState(false);
  const bindHover = useHover(({hovering}) => {
    setHover(hovering);
  });

  const {colors} = useTheme();

  return (
    <TouchableOpacity {...bindHover()} onPress={handlePress}>
      <Box
        position="relative"
        flexDirection="row"
        alignItems="center"
        width={130}
        height={34}
        pl={29}
        pr={10}
        my={1}
        backgroundColor={isActive ? '$interactive-03' : '$transparent'}
        borderRadius={5}
      >
        {isActive && (
          <Box
            position="absolute"
            left={5}
            top="50%"
            // @ts-ignore
            style={{transform: 'translateY(-50%)'}}
          >
            <Icon name="check" color="$interactive-01" />
          </Box>
        )}

        <Text color={isActive ? '$text-05' : '$text-01'}>{text}</Text>
      </Box>
    </TouchableOpacity>
  );
};
