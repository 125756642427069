import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {type LoyaltyLevel} from '@youtoken/ui.resource-loyalty';
import {type BoxProps, Box, Text} from '@youtoken/ui.primitives';
import {QuestionTooltip} from '@youtoken/ui.tooltip';
import {AccountLevelProgressBarVariant} from '../../types';
import {AccountLevelProgressBarDetailedTradePerksInfo} from './Info';
import {AccountLevelProgressBarDetailedTradePerksClosed} from './Closed';

interface AccountLevelProgressBarDetailedPerksProps {
  level: LoyaltyLevel;
  variant: AccountLevelProgressBarVariant;
}

export const AccountLevelProgressBarDetailedPerks: React.FC<
  AccountLevelProgressBarDetailedPerksProps & BoxProps
> = observer(({level, variant, ...boxProps}) => {
  const {t} = useTranslation();

  return (
    <Box gap={16} {...boxProps}>
      <Box flexDirection="row" justifyContent="space-between">
        <Text variant="$body-01-high-accent" color="$text-01">
          {variant === AccountLevelProgressBarVariant.HODL
            ? t('surface.loyalty.progress_bar_detailed.perks.opened_trade')
            : t('surface.loyalty.progress_bar_detailed.perks.opened_exchange')}
        </Text>
        <QuestionTooltip
          content={
            variant === AccountLevelProgressBarVariant.HODL
              ? t(
                  'surface.loyalty.progress_bar_detailed.perks.opened_trade_hint'
                )
              : t(
                  'surface.loyalty.progress_bar_detailed.perks.opened_exchange_hint'
                )
          }
          bodyHorizontalOffset={24}
          caretPosition="right"
        />
      </Box>
      <AccountLevelProgressBarDetailedTradePerksInfo
        variant={variant}
        level={level}
      />
      {variant === AccountLevelProgressBarVariant.HODL && (
        <AccountLevelProgressBarDetailedTradePerksClosed />
      )}
    </Box>
  );
});
