import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {type BoxProps, Box, Text} from '@youtoken/ui.primitives';
import {AccountLevelAllBenefitsFeature} from '../Feature';
import {AccountLevelInfoItemSavingsFull} from '../../../components';
import {LevelInfoSavingsItem} from './Item';

export interface LevelInfoSavingsProps extends BoxProps {
  level: number;
}

export const LevelInfoSavings: React.FC<LevelInfoSavingsProps> = observer(
  ({level: levelNumber, ...boxProps}) => {
    const {t} = useTranslation();

    const {
      getSavingsData,
      resources: {
        loyalty: {getLevel},
      },
    } = AccountLevelAllBenefitsFeature.use({});

    const level = getLevel(levelNumber);

    const {period, selected, other} = getSavingsData(levelNumber);

    return (
      <Box testID="LEVEL_INFO_SAVINGS" {...boxProps}>
        <AccountLevelInfoItemSavingsFull level={level} p={0} mb={24} />
        {selected.length > 0 && (
          <Box testID="LEVEL_INFO_SAVINGS_SELECTED" mb={24}>
            <Text
              testID="LEVEL_INFO_SAVINGS_SELECTED_TITLE"
              variant="$body-02-high-accent"
              mb={16}
            >
              {t('surface.loyalty.savings.your_assets')}
            </Text>
            <Box
              testID="LEVEL_INFO_SAVINGS_SELECTED_LIST"
              flexDirection="row"
              flexWrap="wrap"
              mx={-16}
              my={-8}
            >
              {selected.map(({ticker, aprFormatted}) => {
                return (
                  <LevelInfoSavingsItem
                    key={ticker}
                    ticker={ticker}
                    aprFormatted={aprFormatted}
                    px={16}
                    py={8}
                    width={{
                      default: '100%',
                      phone: '50%',
                    }}
                  />
                );
              })}
            </Box>
          </Box>
        )}
        {other.length > 0 && (
          <Box testID="LEVEL_INFO_SAVINGS_OTHER" mb={24}>
            <Text
              testID="LEVEL_INFO_SAVINGS_OTHER_TITLE"
              variant="$body-02-high-accent"
              mb={16}
            >
              {t('surface.loyalty.savings.all_assets')}
            </Text>
            <Box
              testID="LEVEL_INFO_SAVINGS_OTHER_LIST"
              flexDirection="row"
              flexWrap="wrap"
              mx={-16}
              my={-8}
            >
              {other.map(({ticker, aprFormatted}) => {
                return (
                  <LevelInfoSavingsItem
                    key={ticker}
                    ticker={ticker}
                    aprFormatted={aprFormatted}
                    px={16}
                    py={8}
                    width={{
                      default: '100%',
                      phone: '50%',
                    }}
                  />
                );
              })}
            </Box>
          </Box>
        )}
        <Text
          testID="LEVEL_INFO_SAVINGS_PERIOD"
          variant="$body-02"
          color="$text-02"
          mt={16}
        >
          {t('surface.loyalty.savings.period_info', {period})}
        </Text>
      </Box>
    );
  }
);
