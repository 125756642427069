import * as React from 'react';
import {Text, TouchableBox} from '@youtoken/ui.primitives';
import {Icon} from '@youtoken/ui.icons';
import {IconName} from '@youtoken/ui.icons/src/Icon';

export const PASSCODE_BUTTON_SIZE = 72;

type PasscodeButtonProps = {
  number?: number;
  icon?: IconName;
  type?: 'secondary' | 'ghost';
  disabled?: boolean;
  onPress: (number?: number) => void;
};

export const PasscodeButton: React.FC<PasscodeButtonProps> = ({
  number,
  icon,
  type = 'secondary',
  disabled = false,
  onPress,
}) => {
  const handlePress = React.useCallback(() => {
    onPress(number);
  }, [onPress, number]);

  return (
    <TouchableBox
      width={72}
      height={72}
      borderRadius={36}
      justifyContent="center"
      alignItems="center"
      opacity={disabled ? 0.6 : 1}
      activeOpacity={disabled ? 0 : 0.8}
      onPress={handlePress}
      backgroundColor={
        type === 'secondary' ? '$interactive-02' : '$ui-background'
      }
      disabled={disabled}
    >
      {typeof number !== 'undefined' && (
        <Text
          fontSize={32}
          lineHeight={72}
          color="$interactive-01"
          weight="bold"
        >
          {number}
        </Text>
      )}

      {icon && <Icon name={icon} size={32} color="$interactive-01" />}
    </TouchableBox>
  );
};
