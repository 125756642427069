import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Box, Text} from '@youtoken/ui.primitives';

export const AccountLevelCurrent: React.FC = observer(() => {
  const {t} = useTranslation();

  return (
    <Box backgroundColor="$interactive-01" px={8} py={4} borderRadius={24}>
      <Text color="$text-04" variant="$body-03">
        {t('surface.loyalty.your_level')}
      </Text>
    </Box>
  );
});
