import * as React from 'react';
import {Box, Text} from '@youtoken/ui.primitives';
import {Button} from '@youtoken/ui.buttons';
import {Icon, type IconName} from '@youtoken/ui.icons/src/Icon';

export interface AccountLevelProgressActionProps {
  title: string;
  description?: string;
  iconName?: IconName;
  onPress?: () => void;
}

export const AccountLevelProgressAction: React.FC<
  AccountLevelProgressActionProps
> = ({children, title, description, iconName, onPress}) => {
  return (
    <>
      {Boolean(iconName) && (
        <Box
          flex={1}
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
          mb={8}
        >
          <Icon name={iconName} size={16} color="$attention-01" />
          <Box mx={4}>
            <Icon name={iconName} size={24} color="$attention-01" />
          </Box>
          <Icon name={iconName} size={16} color="$attention-01" />
        </Box>
      )}
      <Box flexDirection="column" justifyContent="center">
        <Text
          variant="$body-01-high-accent"
          color="$text-01"
          textAlign="center"
          mb={4}
        >
          {title}
        </Text>
        <Text variant="$body-01" color="$text-02" textAlign="center">
          {description}
        </Text>
      </Box>
      {Boolean(onPress) && (
        <Button
          testID="ACCOUNT_LEVEL_DETAILED_WIDGET_ACTION_BUTTON"
          size="large"
          width="100%"
          mt={16}
          onPress={onPress}
        >
          {children}
        </Button>
      )}
    </>
  );
};
