export type ModalNotificationsHandler = (notificationId: string) => void;

export class LocalModalNotificationsService {
  private _request?: ModalNotificationsHandler = undefined;

  public provideHandler = (handler: ModalNotificationsHandler) => {
    this._request = handler;
  };

  public request = (notificationId: string) => {
    return this._request(notificationId);
  };
}
